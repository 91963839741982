import Modal from 'components/shared/modal/Modal';
import { Form, Formik } from 'formik';
import React, { memo, useMemo } from 'react'
import { dateConverter, processArray, requiredMessage } from 'utils/utils';
import * as Yup from "yup"
import FormikControl from 'components/formikcontrols/FormikControl';
import styles from "./HomeFilter.module.scss"
import Button from 'components/shared/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { privateGet, privatePost, privatePut } from 'services/privateRequest';
import toast from 'components/shared/toast/toast';
import { setSelectedMaster } from 'redux/slices/masterSlice';
// import { setFilterValues } from 'redux/slices/accountsSlice';
import { EXPORT_FINAL_CSV, SSR_REPORT } from 'services/apiEndpoints';
import axios from 'axios';

const HomeFilter = ({ modalOpen, setModalOpen, name, filterValues, setFilterValues, handleDownload, options }) => {
    // const { filterValues } = useSelector(state => state.accounts)
    // const { accounts } = useSelector(state => state.accounts)

    // const userOptions = accounts?.map((item) => ({
    //     value: item?.id,
    //     label: item?.account_holder_name
    // }))

    const dispatch = useDispatch()

    const clearFilter = () => {
        // setFilterValues(prev => ({
        //     ...prev,
        //     startDate: "",
        //     endDate: ""
        // }))
        setModalOpen(false)
    }

    const initialValues = name == 'ssr' ? {
        user: "",
        startDate:  filterValues?.startDate || "",
        endDate: filterValues?.endDate || "",
    } : {
        startDate: filterValues?.startDate || "",
        endDate:  filterValues?.endDate || "",
    } 

    const validationSchema = Yup.object({
        startDate: Yup.string(),
        endDate: Yup.string(),
    })

    const handleSubmit = async (values, { resetForm }) => {
        if(name) {
            handleDownload(values)
        } else {
            const payload = { ...filterValues || {}, ...values }
            dispatch(setFilterValues(payload))
            // setFilterValues(payload);
            localStorage.setItem("filterValues", JSON.stringify(payload))
            setModalOpen(false)
            resetForm()
        }
    }

    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    }

    const todayDate = getTodayDate();

    return (
        <Modal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            heading={name || `Filter`}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {formik => {
                    return (
                        <Form className={styles.form}>
                            {name === "Account Report" && <FormikControl
                                name="user"
                                control="select"
                                label="Account"
                                placeholder="Select user"
                                formik={formik}
                                options={options}
                            // max={formik.values.endDate}
                            isRequired={true}
                            />}
                            <FormikControl
                                name="startDate"
                                control="date"
                                label="Start Date"
                                placeholder="Select Start Date"
                                max={formik.values.endDate || todayDate}
                            // isRequired={true}
                            />
                            <FormikControl
                                name="endDate"
                                control="date"
                                label="End Date"
                                placeholder="Select End Date"
                                min={formik.values.startDate}
                                max={todayDate}
                            // isRequired={true}
                            />
                            <span className={styles.buttonsContainer}>
                                <Button
                                    className={styles.cancelButton}
                                    onClick={clearFilter}
                                >
                                    Close
                                </Button>
                                <Button type="submit">
                                    {!name ? `Apply` : `Download`}
                                </Button>
                            </span>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    );
}

export default memo(HomeFilter);