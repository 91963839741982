import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { USER, USER_APPROVER } from "services/apiEndpoints";
import { privateDelete, privateGet, privatePost, privatePut } from "services/privateRequest";
import { getDefaultDates } from "utils/utils";

const initialState = {
    users: [],
    selectedUser: null,
    userModalOpen: false,
    isFetching: false,
    totalCount: 0,
    allUsers: [],
    userApproverList: [],
    userFilterValues: {
        ...getDefaultDates(),
        // ...JSON.parse(localStorage.getItem("filterValues")) || {},
    }
}

export const getUsersData = createAsyncThunk("users/getUsersData", async (params) => {
    const res = await privateGet(USER, { params })
    return {
        data: res?.data?.data || [],
        totalCount: res?.data?.count || 0
    }
})

export const getAllUsersData = createAsyncThunk("users/getAllUsersData", async (params) => {
    const res = await privateGet(USER + "/list", {params})
    return {
        allUsers: res?.data?.data || [],
        totalCount: res?.data?.count || 0
    }
})

export const getUserApproverData = createAsyncThunk("users/getUserApprover", async (id) => {
    const res = await privateGet(USER_APPROVER + '/' + id + '?approver=true')

    const data = res?.data?.data || []
    return { data }
})

export const deleteUser = createAsyncThunk("entity/deleteUser", async (id, { dispatch }) => {
    await privateDelete(USER, id)
    toast.success("User InActive Successfully")
})

export const restoreUser = createAsyncThunk("entity/restoreUser", async (id, { dispatch }) => {
    await privatePut(USER + '/restore', id)
    toast.success("User Active Successfully")
})

const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
            state.userModalOpen = !!action.payload
        },
        setUserModalOpen: (state, action) => {
            state.userModalOpen = action.payload
        },
        setUserFilterValues: (state, action) => {
            state.userFilterValues = action.payload
        },
        setUserApproverListEmpty: (state, action) => {
            state.userApproverList = []
        },
    },
    extraReducers: {
        [getUsersData.pending]: (state, action) => {
            state.isFetching = true
        },
        [getUsersData.fulfilled]: (state, action) => {
            state.users = action.payload.data
            state.totalCount = action.payload.totalCount
            state.isFetching = false
        },
        [getUsersData.rejected]: (state, action) => {
            state.isFetching = false
        },
        [getAllUsersData.fulfilled]: (state, action) => {
            state.allUsers = action.payload.allUsers
        },
        [getUserApproverData.fulfilled]: (state, action) => {
            state.userApproverList = action.payload.data
        },
    }
})

export default userSlice.reducer
export const { setIsFetching, setSelectedUser, setUserModalOpen, setUserApproverListEmpty, setUserFilterValues } = userSlice.actions