import usersSlice from "./slices/usersSlice"
import homeSlice from "./slices/homeSlice"
import rolesAndPermissionSlice from "./slices/rolesAndPermissionSlice"
import entitySlice from "./slices/entitySlice"
import masterSlice from "./slices/masterSlice"
import pendingByApproverSlice from "./slices/pendingByApproverSlice"
import userDetailsSlice from "./slices/userDetailsSlice"
import receiptWalletSlice from "./slices/receiptWalletSlice"
import ccSlice from "./slices/ccSlice"
import approverSlice from "./slices/approverSlice"
import accountsSlice from "./slices/accountsSlice"
import userAccountSlice from "./slices/userAccountSlice"
import reportSlice from "./slices/reportSlice"
import globalSlice from "./slices/globalSlice"
import emailConfigurationSlice from "./slices/emailConfigurationSlice"

const rootReducer = {
    global: globalSlice,
    users: usersSlice,
    home: homeSlice,
    rolesAndPermission: rolesAndPermissionSlice,
    entity: entitySlice,
    master:masterSlice,
    pendingByApprover: pendingByApproverSlice,
    userDetails: userDetailsSlice,
    receiptWallet: receiptWalletSlice,
    cc: ccSlice,
    approver: approverSlice,
    accounts: accountsSlice,
    userAccount: userAccountSlice,
    report: reportSlice,
    emailConfiguration: emailConfigurationSlice,
}

export default rootReducer