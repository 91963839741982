import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { EMAIL_CONFIGURATION, EMAIL_REMAINDER } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";

const initialState = {
    emailConfiguration: [],
    totalEmailConfiguration: 0,
    selectedEmailConfiguration: null,
    emailConfigurationModalOpen: false,
    isFetching: false,
    allEmailConfiguration: [],
}

// EmailConfiguration
export const getEmailConfigurationData = createAsyncThunk("emailConfiguration/getEmailConfigurationData", async({activeTab, ...params}) => {

    const res = await privateGet(activeTab ? EMAIL_REMAINDER : EMAIL_CONFIGURATION, { params })
    return {
        data: res?.data?.data || [],
        totalEmailConfiguration: res?.data?.count || 0
    }
})

export const getEmailReminderData = createAsyncThunk("emailConfiguration/getEmailReminderData", async(params) => {
    const res = await privateGet(EMAIL_REMAINDER, { params })
    return {
        data: res?.data?.data || [],
        totalEmailConfiguration: res?.data?.count || 0
    }
})

export const getAllEmailConfigurationData = createAsyncThunk("emailConfiguration/getAllEmailConfigurationData", async() => {
    const res = await privateGet(EMAIL_CONFIGURATION)
    return {
        data: res?.data?.data || [],
    }
})

export const deleteEmailConfiguration = createAsyncThunk("emailConfiguration/deleteEmailConfiguration", async({ id, activeTab}, { dispatch }) => {
    await privateDelete(activeTab ? EMAIL_REMAINDER : EMAIL_CONFIGURATION, id)
    // dispatch(getEmailConfigurationData())
    toast.success(`Email ${activeTab ? "Recipient" : "Configuration"} deleted successfully`)
})

const emailConfigurationSlice = createSlice({
    name: "emailConfiguration",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedEmailConfiguration: (state, action) => {
            state.selectedEmailConfiguration = action.payload
            state.emailConfigurationModalOpen = !!action.payload
        },
        setEmailConfigurationModalOpen: (state, action) => {
            state.emailConfigurationModalOpen = action.payload
        }
    },
    extraReducers: {
        [getEmailConfigurationData.pending]: (state) => {
            state.isFetching = true
        },
        [getEmailConfigurationData.fulfilled]: (state, action) => {
            state.emailConfiguration = action.payload.data
            state.totalEmailConfiguration = action.payload.totalEmailConfiguration
            state.isFetching = false
        },
        [getEmailConfigurationData.rejected]: (state) => {
            state.isFetching = false
        },
        [getEmailReminderData.pending]: (state) => {
            state.isFetching = true
        },
        [getEmailReminderData.fulfilled]: (state, action) => {
            state.emailConfiguration = action.payload.data
            state.totalEmailConfiguration = action.payload.totalEmailConfiguration
            state.isFetching = false
        },
        [getEmailReminderData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllEmailConfigurationData.fulfilled]: (state, action) => {
            state.allEmailConfiguration = action.payload.data
        },
    }
})

export default emailConfigurationSlice.reducer
export const { setIsFetching, setSelectedEmailConfiguration, setEmailConfigurationModalOpen } = emailConfigurationSlice.actions