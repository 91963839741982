import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { ENTITY, USER_APPROVER } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";
import { getDefaultDates } from "utils/utils";

const initialState = {
    approver: [],
    totalApprover: 0,
    selectedApprover: null,
    approverModalOpen: false,
    isFetching: false,
    allApprover: [],
    approverFilterValues: {
        ...getDefaultDates(),
        // ...JSON.parse(localStorage.getItem("filterValues")) || {},
    }
}

// Approver
export const getApproverData = createAsyncThunk("approver/getApproverData", async(params) => {
    const res = await privateGet(USER_APPROVER, { params })
    // const res = {
    //     data: {
    //         data: await new Promise(resolve => {
    //             setTimeout(() => {
    //                 resolve(Array(12).fill(null).map((_, i) => ({
    //                     id: i + 1,
    //                     user: "Athreya Murthy",
    //                     approver: ["Siddhesh Mahale"]
    //                 })))
    //             }, 1000)
    //         }),
    //         count: 12
    //     }
    // }
    return {
        data: res?.data?.data || [],
        totalApprover: res?.data?.count?.[0]?.count || 0
    }
})

export const getAllApproverData = createAsyncThunk("approver/getAllApproverData", async() => {
    const res = await privateGet(ENTITY)
    return {
        data: res?.data?.data || [],
    }
})

export const deleteApprover = createAsyncThunk("approver/deleteApprover", async(id, { dispatch }) => {
    await privateDelete(ENTITY, id)
    dispatch(getApproverData())
    toast.success("Approver deleted successfully")
})

const approverSlice = createSlice({
    name: "approver",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedApprover: (state, action) => {
            state.selectedApprover = action.payload
            state.approverModalOpen = !!action.payload
        },
        setApproverModalOpen: (state, action) => {
            state.approverModalOpen = action.payload
        },
        setApproverFilterValues: (state, action) => {
            state.approverFilterValues = action.payload
        },
    },
    extraReducers: {
        [getApproverData.pending]: (state) => {
            state.isFetching = true
        },
        [getApproverData.fulfilled]: (state, action) => {
            state.approver = action.payload.data
            state.totalApprover = action.payload.totalApprover
            state.isFetching = false
        },
        [getApproverData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllApproverData.fulfilled]: (state, action) => {
            state.allApprover = action.payload.data
        },
    }
})

export default approverSlice.reducer
export const { setIsFetching, setSelectedApprover, setApproverModalOpen, setApproverFilterValues } = approverSlice.actions