import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { RECEIPT_WALLET, RECEIPT_WALLET_NEW } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";

const initialState = {
    receiptWallet: [],
    totalReceiptWallet: 0,
    selectedReceiptWallet: null,
    receiptWalletModalOpen: false,
    isFetching: false,
    allReceiptWallet: [],
}

// ReceiptWallet
export const getReceiptWalletData = createAsyncThunk("receiptWallet/getReceiptWalletData", async({userId, params = {}}) => {
    // const res = await privateGet(`${RECEIPT_WALLET}/by-user/${userId}`, { params: { ...params, isCcReceipt: true }})
    const res = await privateGet(`${RECEIPT_WALLET_NEW}`, { params: { ...params, userId }})
    // const res = {
    //     data: {
    //         data: await new Promise(resolve => {
    //             setTimeout(() => {
    //                 resolve(Array.from({length: 14}).map((_, i) => ({
    //                     id: i + 1,
    //                     fileName: "IMG95837.pdf",
    //                     date: "25 May 2023",
    //                     merchantName: "ICICI bank"
    //                 })))
    //             }, 1000)
    //         })
    //     },
    //     count: 14
    // }
    return {
        data: res?.data?.data || [],
        totalReceiptWallet: res?.data?.count || 0
    }
})

export const getAllReceiptWalletData = createAsyncThunk("receiptWallet/getAllReceiptWalletData", async() => {
    const res = await privateGet(RECEIPT_WALLET, { params: { isCcReceipt: true }})
    return {
        data: res?.data?.data || [],
    }
})

export const deleteReceiptWallet = createAsyncThunk("receiptWallet/deleteReceiptWallet", async(id, { dispatch }) => {
    await privateDelete(RECEIPT_WALLET_NEW, id)
    toast.success("Receipt deleted successfully")
})

const receiptWalletSlice = createSlice({
    name: "receiptWallet",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedReceiptWallet: (state, action) => {
            state.selectedReceiptWallet = action.payload
            state.receiptWalletModalOpen = !!action.payload
        },
        setReceiptWalletModalOpen: (state, action) => {
            state.receiptWalletModalOpen = action.payload
        }
    },
    extraReducers: {
        [getReceiptWalletData.pending]: (state) => {
            state.isFetching = true
        },
        [getReceiptWalletData.fulfilled]: (state, action) => {
            state.receiptWallet = action.payload.data
            state.totalReceiptWallet = action.payload.totalReceiptWallet
            state.isFetching = false
        },
        [getReceiptWalletData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllReceiptWalletData.fulfilled]: (state, action) => {
            state.allReceiptWallet = action.payload.data
        },
    }
})

export default receiptWalletSlice.reducer
export const { setIsFetching, setSelectedReceiptWallet, setReceiptWalletModalOpen } = receiptWalletSlice.actions