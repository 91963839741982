import { PATH } from "./constants";

// export const sideBarNavigation = Object.values(PORTALS_NAMES).reduce((acc, portalName) => {
//     acc[portalName] = Object.values(PATH[portalName].children)
//         .filter(x => x.sidebar)
//         .map((pageData, index) => ({
//             id: index + 1,
//             path: pageData.path,
//             icon: BALANCESHEET_ICON,
//             pageName: pageData.sidebar.name || pageData.pageName
//         }))
//     return acc
// }, {})

export const sideBarNavigation = Object.values(PATH.privateRoutes)
        .filter(x => x?.sidebar?.show)
        .map((pageData, index) => ({
            id: index + 1,
            path: pageData.path,
            icon: pageData.sidebar.icon,
            pageName: pageData.sidebar.name || pageData.pageName
        }))