import React from 'react'
import styles from "./Modal.module.scss"
import { combineClasses } from 'utils/utils';

const Modal = ({
    modalOpen,
    setModalOpen,
    heading,
    children,
    doNotClose = false,
    className = "",
    isSubmitting
}) => {
    if(modalOpen) {
        return (  
            <div 
                className={styles.mainContainer} 
                onMouseDown={(e) => {
                    e.stopPropagation()
                    !isSubmitting && !doNotClose && setModalOpen(false)
                }}
                onKeyDown={e => {
                    if(e.key === "Enter")
                    e.preventDefault()
                }}
            >
                <div className={combineClasses(styles.modalDiv, className)} onMouseDown={(e) => e.stopPropagation()}>
                    {heading && <div className={styles.modalHeader}>
                        <p>{heading}</p>
                    </div>}
                    <div className={combineClasses(styles.modalBody, className ? "modalBody": "" )}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Modal;