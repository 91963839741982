import React, { useState } from "react";
import styles from "./Login.module.scss";
import { useNavigate } from "react-router-dom";
import toast from "components/shared/toast/toast";
import { emailRegex } from "utils/regex";
import { LOGIN } from "services/apiEndpoints";
import publicRequest from "services/publicRequest";
import LoginLayout from "components/layouts/loginlayout/LoginLayout";
import * as Yup from "yup"
import { combineClasses, getDefaultDates, requiredMessage } from "utils/utils";
import { Form, Formik } from 'formik';
import FormikControl from "components/formikcontrols/FormikControl";
import Button from 'components/shared/button/Button';
import SelectRoleModal from './../../shared/selectrolemodal/SelectRoleModal';
import { setCardLimitModalOpen } from "redux/slices/globalSlice";
import { useDispatch } from "react-redux";
import LoadingText from "components/shared/loadingtext/LoadingText";
import { setFilterValues } from "redux/slices/accountsSlice";
import { PATH } from "utils/constants";

const Login = () => {

  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: ""
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .required(requiredMessage("Email ID"))
      .matches(emailRegex, "Invalid email format!")
    ,
    password: Yup.string().required(requiredMessage("Password"))
  })

  const login = (values) => {
    return publicRequest.post(LOGIN, values).then((res) => {
      const { id, token, userRole, permission, isOnlyUser, isUserAndApprover, isVip, approverList } = res?.data || {}
      localStorage.setItem("token", token)
      localStorage.setItem("currentUser", JSON.stringify({
        token,
        role: userRole?.[0]?.roleId,
        roleName: userRole?.[0]?.role?.roleName,
        isOnlyUser,
        isUserAndApprover,
        permission: permission.map(({ id, permission:perm }) => ({
          id,
          permissionName: perm.actionName,
          entityId: perm.entity.id,
          entityName: perm.entity.entityName
        })),
        userId: id,
        isVip,
        approverList,
      }))
      localStorage.setItem("email", values.email)
      dispatch(setFilterValues(getDefaultDates()))
      if(userRole?.[0]?.role?.roleName?.toLowerCase() === "admin" || userRole?.[0]?.role?.roleName?.toLowerCase() === "internaluser") {
        navigate("/")
        localStorage.setItem("currentRole", "admin")
      } 
      // else if(values.email === "shandharod@yahoo.com") {
      //   navigate("/")
      //   localStorage.setItem("currentRole", "approver")
      // } 
      else if(userRole?.[0]?.role?.roleName?.toLowerCase() === "developer") {
        console.log('developer role')
        localStorage.setItem("currentRole", "developer");
        navigate("/app-logger")
      }
      else if(!isUserAndApprover) {
        navigate("/")
        localStorage.setItem("currentRole", isOnlyUser ? "user" : "approver")
        dispatch(setCardLimitModalOpen(true))
      }
      else {
        setModalOpen(true)
        dispatch(setCardLimitModalOpen(true))
      }
    })
  }

  const handleSubmit = async (values) => {
    const p = login(values)
    await toast.promise(p, {
      loading: <>Logging in<LoadingText style={{ alignSelf: "flex-end", paddingBottom: "2px" }} /></>,
      success: () => "Login Successful",
      error: (err) => err?.response?.data?.message || "Invalid Email or Password",
    })
  };

  const modalProps = {
    modalOpen,
    setModalOpen
  }

  return (
    <>
    <SelectRoleModal 
      {...modalProps}
    />
    <LoginLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => {
          return (
            <div className={styles.mainDiv}>
              <p className={styles.heading}>Welcome back,</p>
              <p className={styles.subHeading}>Let's sign in your account</p>
              <Form className={styles.form} autoComplete="off">
                <FormikControl
                  name="email"
                  control="input"
                  label="Email ID"
                  placeholder="Enter Email ID"
                  isRequired={true}
                  formik={formik}
                />
                <FormikControl
                  name="password"
                  control="password"
                  type="password"
                  label="Password"
                  placeholder="Enter Password"
                  isRequired={true}
                />
                <Button 
                  type="submit" 
                  className={styles.button} 
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? <LoadingText text={"Logging in"}/> :"Login"}
                </Button>
                {/* <p className={styles.bottomText}>
                  Don't have an account? <span 
                    className="link-text" 
                    onClick={() => navigate(PATH.publicRoutes.REGISTER.path)}
                  >
                    Sign up
                  </span>
                </p> */}
                <p 
                  className={combineClasses(styles.bottomText, "link-text")}
                  onClick={() => navigate(PATH.publicRoutes.FORGOT_PASSWORD.path)}
                >
                  Forgot Password?
                </p>
              </Form>
            </div>
          )
        }}
      </Formik>
    </LoginLayout>
    </>
  );
};

export default Login;
