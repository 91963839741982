import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { ENTITY, PENDING_BY_APPROVER } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";

const initialState = {
    pending: [],
    totalPending: 0,
    selectedPending: null,
    pendingModalOpen: false,
    isFetching: false,
    allPending: [],
}

// Pending
export const getPendingData = createAsyncThunk("pending/getPendingData", async(params) => {
    const res = await privateGet(PENDING_BY_APPROVER, { params })
    
    return {
        data: res?.data?.data || [],
        totalPending: res?.data?.count || 0
    }
})

export const getAllPendingData = createAsyncThunk("pending/getAllPendingData", async() => {
    const res = await privateGet(ENTITY)
    return {
        data: res?.data?.data || [],
    }
})

export const deletePending = createAsyncThunk("pending/deletePending", async(id, { dispatch }) => {
    await privateDelete(ENTITY, id)
    dispatch(getPendingData())
    toast.success("Pending deleted successfully")
})

const pendingByApproverSlice = createSlice({
    name: "pending",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedPending: (state, action) => {
            state.selectedPending = action.payload
            state.pendingModalOpen = !!action.payload
        },
        setPendingModalOpen: (state, action) => {
            state.pendingModalOpen = action.payload
        }
    },
    extraReducers: {
        [getPendingData.pending]: (state) => {
            state.isFetching = true
        },
        [getPendingData.fulfilled]: (state, action) => {
            state.pending = action.payload.data
            state.totalPending = action.payload.totalPending
            state.isFetching = false
        },
        [getPendingData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllPendingData.fulfilled]: (state, action) => {
            state.allPending = action.payload.data
        },
    }
})

export default pendingByApproverSlice.reducer
export const { setIsFetching, setSelectedPending, setPendingModalOpen } = pendingByApproverSlice.actions