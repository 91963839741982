import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { MASTER, MASTER_PROPERTY, PROP_WISE_UNIT } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";

const initialState = {
    activeTab: 0,
    masterData: [],
    totalMaster: 0,
    allPropList:[],
    selectedMaster: null,
    propModalOpen: false,
    propWiseUnitData: [],
    propWiseUnitCount: 0,
    glCodeModalOpen: false,
    entityModalOpen: false,
    unitModalOpen: false,
    isFetching: false,
    allMaster: [],
    // data: masterTabNames.reduce((acc, tab) => {
    //     acc[tab] = []
    //     return acc
    // }, {}),
}


export const getMasterData = createAsyncThunk("master/getMasterData", async ({apiEndpoint,params}) => {
    const res = await privateGet(apiEndpoint,{params}); 
    return {
      data: res?.data?.data || [],
      totalMaster: res?.data?.count || 0,
    };
  });
export const getAllMasterData = createAsyncThunk("master/getAllMasterData", async() => {
    const res = await privateGet(MASTER)
    return {
        data: res?.data?.data || [],
    }
})

export const getAllPropList = createAsyncThunk("master/getAllPropList", async() => {
    const res = await privateGet(MASTER_PROPERTY + "?all=true")
    return {
        allPropList: res?.data?.data || [],
    }
})

export const getPropWiseUnitData = createAsyncThunk("master/getPropWiseUnitData", async({ id, params }) => {
    const res = await privateGet(`${PROP_WISE_UNIT}/${id}`, {params})
    return {
        data: res?.data?.data || [],
        totalCount: res?.data?.count || 0
    }
})

export const deleteMaster = createAsyncThunk("master/deleteMaster", async ({apiEndpoint, id }, { dispatch }) => {
    //const apiEndpoint = masterGetAPIEndpoints[activeTab];
    await privateDelete(apiEndpoint, id);
    dispatch(getMasterData());
    toast.success("Code Deleted successfully");
  });
  

const masterSlice = createSlice({
    name: "master",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedMaster: (state, action) => {
            state.selectedMaster = action.payload
            state.selectedEntity = action.payload
            state.propModalOpen = !!action.payload
            state.entityModalOpen = !! action.payload
            state.unitModalOpen = !! action.payload
            state.glCodeModalOpen = !! action.payload
        },
        setPropModalOpen: (state, action) => {
            state.propModalOpen = action.payload
        },
        setEntityModalOpen: (state, action) => {
            state.entityModalOpen = action.payload
        },
        setUnitModalOpen: (state, action) => {
            state.unitModalOpen = action.payload
        },
        setGLCodeModalOpen: (state, action) => {
            state.glCodeModalOpen = action.payload
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
            state.masterData = state.activeTab == action.payload ? state.masterData : []
            state.totalMaster = 0
        },
        clearData: (state) => {
            state.masterData = []
            state.totalMaster = 0
        } 
    },
    extraReducers: {
        [getMasterData.pending]: (state) => {
            state.isFetching = true
        },
        [getMasterData.fulfilled]: (state, action) => {
            state.masterData = action.payload.data
            state.totalMaster = action.payload.totalMaster
            state.isFetching = false
        },
        [getMasterData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllMasterData.fulfilled]: (state, action) => {
            state.allMaster = action.payload.data
        },
        [getAllPropList.fulfilled]: (state, action) => {
            state.allPropList = action.payload.allPropList
        },
        [getPropWiseUnitData.pending]: (state, action) => {
            state.isFetching = true
        },
        [getPropWiseUnitData.fulfilled]: (state, action) => {
            state.propWiseUnitData = action.payload.data
            state.propWiseUnitCount = action.payload.totalCount
            state.isFetching = false
        },
        [getPropWiseUnitData.rejected]: (state, action) => {
            state.isFetching = false
        },
    }
})

export default masterSlice.reducer
export const { 
    setIsFetching, 
    setSelectedMaster, 
    setPropModalOpen,
    setUnitModalOpen,
    setEntityModalOpen,
    setGLCodeModalOpen,
    setActiveTab,
    clearData, 
} = masterSlice.actions