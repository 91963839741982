import React, { useMemo, useState } from 'react'
import styles from './Table.module.scss'
import PAGINATION_ARROW from "assets/png/arrow.png"
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import { PAGINATION } from 'utils/constants';
import { useSelector } from 'react-redux';
import { combineClasses } from 'utils/utils';

const Pagination = (props) => {

    const { sidebarOpen } = useSelector(state => state.home)
    const { currentPage, setPageSelected: setCurrentPage, isLastPage } = props
    const [rowsPerPageValue, setRowsPerPageValue] = useState(props?.rowsPerPageValue || PAGINATION.DEFAULT_ROWS_PER_PAGE)
    // const [currentPage, setCurrentPage] = useState(props?.currentPage || PAGINATION.DEFAULT_CURRENT_PAGE)
    const noOfPages = useMemo(() => Math.ceil(+props?.totalCount / rowsPerPageValue), [rowsPerPageValue, props?.totalCount])

    const endingData = useMemo(() => currentPage * rowsPerPageValue, [currentPage, rowsPerPageValue])
    const startingData = useMemo(() => {
        if (currentPage === 1) {
            return 1
        } else if (endingData > props?.totalCount) {
            return ((noOfPages - 1) * rowsPerPageValue) + 1
        } else {
            return ((currentPage - 1) * rowsPerPageValue) + 1
        }
    }, [currentPage, rowsPerPageValue, endingData, props?.totalCount])

    // const handleRowsChange = (e) => {
    //     setRowsPerPageValue(e.target.value)
    //     props?.setRowsPerPageValue(e.target.value)
    // }

    const handlePageClick = (e) => {
        setCurrentPage(e?.selected + 1)
        props?.setPageSelected(e?.selected + 1)

    }

    return (
        <div className={combineClasses(styles.pagination, sidebarOpen ? styles.sidebarOpen: "")}>
            <p className={styles.dataRange}>
                {`${+props?.totalCount ? startingData : 0} - ${endingData > props?.totalCount ? props?.totalCount : endingData} ${props?.tableName} out of ${props?.totalCount}`}
            </p>
            <div className={styles.paginationRightSide}>
                {/* <span className={styles.dropdownText}>Rows per page :</span>
                <select
                    onChange={handleRowsChange}   
                    value={rowsPerPageValue}
                    className={styles.rowPerPageDropdown}
                >
                    {PAGINATION?.ROWS_PER_PAGE?.map(data=>{
                        return(
                            <option 
                                key={data}
                                value={data}
                            >
                                {data}
                            </option>
                        )
                    })}
                </select> */}
                <ReactPaginate
                    breakLabel=". . ."
                    breakClassName={styles.break}
                    previousClassName={styles.arrowBox}
                    previousLabel={<img src={PAGINATION_ARROW} alt='previous page' className={classNames(styles.arrow, styles.previousArrow)} />}
                    nextClassName={classNames(styles.arrowBox, isLastPage ? styles.disabled : '') }
                    nextLabel={<img src={PAGINATION_ARROW} alt='next page' className={classNames(styles.arrow, styles.nextArrow)} />}
                    renderOnZeroPageCount={null} 
                    pageCount={props?.totalCount / rowsPerPageValue}
                    containerClassName={styles.paginationContainer}
                    activeClassName={styles.activePage}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    disabledClassName={styles.disabled}
                    pageClassName={styles.page}
                    forcePage={currentPage - 1}
                    // forcePage={endingData > props?.totalCount ? Math.floor(noOfPages) : currentPage - 1}
                />
            </div>
        </div>
    )
}

export default Pagination