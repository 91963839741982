import React, { useState } from "react";
import styles from "./OtpVerification.module.scss";
import { useNavigate } from "react-router-dom";
import toast from "components/shared/toast/toast";
import { emailRegex } from "utils/regex";
import { FORGOT_PASSWORD, LOGIN } from "services/apiEndpoints";
import publicRequest from "services/publicRequest";
import LoginLayout from "components/layouts/loginlayout/LoginLayout";
import * as Yup from "yup"
import { combineClasses, requiredMessage } from "utils/utils";
import { Form, Formik } from 'formik';
import FormikControl from "components/formikcontrols/FormikControl";
import Button from 'components/shared/button/Button';
import { PATH } from "utils/constants";
import LoadingText from "components/shared/loadingtext/LoadingText";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: "",
  }

  const validationSchema = Yup.object({
    email: Yup.string().required(requiredMessage("Email ID")).matches(emailRegex, "Invalid email format!"),
  })

  // const login = (values) => {
  //   return publicRequest.post(LOGIN, values).then((res) => {
  //     localStorage.setItem("token", res?.data?.token)
  //     localStorage.setItem("currentUser", JSON.stringify(res?.data || {}))
  //     localStorage.setItem("email", values.email)
  //     navigate("/")
  //   })
  // }

  const forgotPassword = (values) => {
    return publicRequest.post(FORGOT_PASSWORD, values)
  }

  const handleSubmit = async (values) => {
    // navigate(PATH.publicRoutes.OTP_VERIFICATION.path, { state: { email: values.email } })
    const p = forgotPassword(values)
    await toast.promise(p, {
      loading: <>Email sending<LoadingText style={{ alignSelf: "flex-end", paddingBottom: "2px" }} /></>,
      success: () => "Email sent successfully",
      error: () => "Email not found",
    })
    navigate(PATH.publicRoutes.LOGIN.path)
  };

  return (
    <LoginLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => {
          return (
            <div className={styles.mainDiv}>
              <p className={styles.heading}>Forgot Password</p>
              <p className={styles.subHeading}>Please Enter Email ID</p>
              <Form className={styles.form} autoComplete="off">
                <FormikControl
                  name="email"
                  control="input"
                  label="Email ID"
                  placeholder="Enter Email ID"
                  isRequired={true}
                />
                <Button 
                  type="submit" 
                  className={styles.button} 
                  disabled={formik.isSubmitting}
                >
                  Submit
                </Button>
                {/* <p className={styles.bottomText}>
                  Don't have an account? <span 
                    className="link-text" 
                    onClick={() => navigate(PATH.publicRoutes.REGISTER.path)}
                  >
                    Sign up
                  </span>
                </p> */}
              </Form>
            </div>
          )
        }}
      </Formik>
    </LoginLayout>
  );
};

export default ForgotPassword;
