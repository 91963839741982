import Modal from 'components/shared/modal/Modal';
import React, { useEffect, useState } from 'react'
import { RECEIPT_WALLET_NEW } from 'services/apiEndpoints';
import { privateGet } from 'services/privateRequest';
import styles from "./ViewAllReceiptDetails.module.scss"
import Button from 'components/shared/button/Button';
import { ReactComponent as DownloadIcon } from "assets/svg/download.svg"
import { combineClasses, download } from 'utils/utils';
import CircleSpinner from 'components/shared/loader/CircleSpinner';

const ViewAllReceiptDetails = ({ modalOpen, setModalOpen, handleConfirm, handleCancel, heading: title, id, data }) => {

    const [receiptData, setReceiptData] = useState(null);
    const [image, setImage] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    const imageUrl = []
    // process.env.REACT_APP_API_BASE_URL + 
    // "/" + (path || receiptData?.path) || ""

    for(let pathLink of (data.path || receiptData?.path || [])) {
        imageUrl.push(`${process.env.REACT_APP_API_BASE_URL}/${pathLink?.path || pathLink}`)
    }

    const getReceiptData = async () => {
        if(!id) return
        setIsFetching(true)
        const res = await privateGet(`${RECEIPT_WALLET_NEW}/${id}`)
        setReceiptData(res?.data?.data)
    }

    const getImage = async() => {
        if(!imageUrl?.length) return
        setIsFetching(true)
        for(let url of imageUrl) {
            fetch(url, {
                method: 'GET',
            })
            .then((response) => response.blob())
            .then((blob) => {
                const imageUrl = URL.createObjectURL(blob);
                setImage(prev => [...prev, imageUrl])
            })
            .catch(error => {
                console.log(error?.message)
            })
        }
        setIsFetching(false)
    }

    const getScreenSize = () => {
        setMobileView(window.innerWidth < 900)
    }

    const heading = (
    <div className={styles.heading}>
    <span>
        {receiptData?.remarksDetailedExpenseDesc || title || ""}
    </span>
    <span className={styles.buttonContainer}>
        <Button 
            onClick={(e) => {
                e.stopPropagation()
                for(let url of imageUrl) {
                    window.open((mobileView && url.endsWith(".pdf") ? "https://docs.google.com/viewerng/viewer?url=" : "") + url, "_blank")
                }
            }}
        >
            Open in New Tab
        </Button>
        <Button 
            onClick={() => {
                for(let url of imageUrl) {
                    download(url)
                }
            }}
        >
            <DownloadIcon style={{margin: 0, height: "16px", padding: 0}} />
        </Button>
    </span>
    </div>)

    useEffect(() => {
        modalOpen && getScreenSize()
        !data.path && modalOpen && getReceiptData()
        return () => {
            setImage([])
            setReceiptData(null)
        }
    }, [modalOpen])

    // useEffect(() => {
    //     if(modalOpen && (data.path || receiptData?.path)) {
    //         !image.length && getImage()
    //     }
    // }, [modalOpen, receiptData])

    return <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} heading={!handleConfirm && heading} className={styles.modalDiv} >
        {handleConfirm && <div className={styles.confirmDiv}>
            Are you sure you want to upload following receipts?
            <span className={styles.buttons}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirm(data)}>Submit</Button>
            </span>
        </div>}
        <div className={styles.allFieldsContainer}>
            <div className={styles.spinnerDiv}>
                <p className={styles.label}>Receipts</p>
                <div className={styles.multipleReceiptsContainer}>
                    {
                        imageUrl?.map((url, i) => {

                            return url.endsWith(".pdf") ?
                                <iframe
                                    src={
                                        mobileView ? 
                                        `https://docs.google.com/gview?embedded=true&url=${imageUrl?.[i]}` :
                                        imageUrl?.[i]
                                    }
                                    frameborder="0"
                                    className={styles.view}
                                ></iframe>
                                :
                                <img
                                    className={styles.view}
                                    src={imageUrl?.[i] || ""}
                                    alt="Receipt"
                                />
                        })
                    }
                </div>
            </div>
            <span className={styles.spinnerDiv}>
                <p className={styles.label}>Description</p>
                <p className={styles.data}>{data?.remarksDetailedExpenseDesc || "-"}</p>
            </span>
            <span className={styles.spinnerDiv}>
                <p className={styles.label}>Amount</p>
                <p className={styles.data}>{data?.amount || "-"}</p>
            </span>
            <span className={styles.spinnerDiv}>
                <p className={styles.label}>WO/RR</p>
                <p className={styles.data}>{data?.worr || "-"}</p>
            </span>
            <span className={styles.spinnerDiv}>
                <p className={styles.label}>Prop Code / Entity Code</p>
                <ol className={styles.propList}>
                {data?.propUnit?.map?.(propUnit => {
                    return <li className={combineClasses(styles.data)}>
                        {propUnit?.propCode?.label || "-"}<br/>
                        <span>Unit Code / Store:</span> {propUnit?.unitCode?.map?.(opt => opt?.label)?.join(", ")}
                    </li>
                })}
                </ol>
            </span>
        </div>
    </Modal>
}

export default ViewAllReceiptDetails;
