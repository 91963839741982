import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { APPROVER_REPORT_DATA, REJECTED_REPORT_DATA, USER_REPORT_DATA } from "services/apiEndpoints";
import { privateGet } from "services/privateRequest";
import { getDefaultDates } from "utils/utils";

const initialState = {
    report: [],
    dropdownData: [],
    totalReport: 0,
    selectedReport: null,
    reportModalOpen: false,
    isFetching: false,
    isFetchingDropdown: false,
    allReport: [],
    rejectedData: [],
    reportFilterValues: {
        ...getDefaultDates(),
        // ...JSON.parse(localStorage.getItem("filterValues")) || {},
    }
}
  
// Report
export const getReportData = createAsyncThunk("report/getReportData", async({activeTab, params}) => {
    const endPoint = activeTab !== 0 ? (activeTab === 1 ? USER_REPORT_DATA : REJECTED_REPORT_DATA ) : APPROVER_REPORT_DATA
    const res =  await privateGet(endPoint, { params });
    return {
        data: res?.data?.data || [],
        totalReport: res?.data?.count || res?.data?.data?.length || 0
    }
})

export const getReportDataById = createAsyncThunk("report/getReportDataById", async({ activeTab, id, params }) => {

    const res = id && await privateGet(
        `${activeTab !== 0 ? (activeTab === 1 ? USER_REPORT_DATA : REJECTED_REPORT_DATA)  : APPROVER_REPORT_DATA}/${id}`, {params}
    )
    return {
        data: res?.data?.data || [],
        totalReport: res?.data?.count || res?.data?.data?.length || 0
    }
})

export const deleteReport = createAsyncThunk("report/deleteReport", async(id, { dispatch }) => {
    
})

const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setReportFilterValues: (state, action) => {
            state.reportFilterValues = action.payload
        },
        // setSelectedReport: (state, action) => {
        //     state.selectedReport = action.payload
        //     state.reportModalOpen = !!action.payload
        // },
        // setReportModalOpen: (state, action) => {
        //     state.reportModalOpen = action.payload
        // }
    },
    extraReducers: {
        [getReportData.pending]: (state) => {
            state.isFetching = true
        },
        [getReportData.fulfilled]: (state, action) => {
            state.report = action.payload.data
            state.totalReport = action.payload.totalReport
            state.isFetching = false
        },
        [getReportData.rejected]: (state) => {
            state.isFetching = false
        },
        [getReportDataById.pending]: (state) => {
            state.isFetchingDropdown = true
        },
        [getReportDataById.fulfilled] : (state, action) => {
            state.dropdownData = action.payload.data
            state.isFetchingDropdown = false
        },
        [getReportDataById.rejected]: (state) => {
            state.isFetchingDropdown = false
        },
    }
})

export default reportSlice.reducer
export const { setIsFetching, setSelectedReport, setReportModalOpen, setReportFilterValues } = reportSlice.actions