import React, { useMemo } from "react";
import styles from "./Button.module.scss";

const Button = ({ outline, className, children, ...props }) => {
  const buttonClassName = useMemo(() => {
    if (outline) {
      return styles.outlineButton;
    } else {
      return styles.primaryButton;
    }
  }, [outline]);

  return (
    <button
      {...props}
      className={`${styles.button} ${className} ${buttonClassName}`}
    >
      {children}
    </button>
  );
};

export default Button;
