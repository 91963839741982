import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notificationModalOpen : false,
    passwordFormatModalOpen: false,
    cardLimitModalOpen: false,
    logoutModalOpen: false,
    dropDownCard: false,
    masterDropdownCard: false
}

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setNotificationModalOpen: (state, action) => {
            state.notificationModalOpen = action.payload
            state.logoutModalOpen = false
        },
        setPasswordFormatModalOpen: (state, action) => {
            state.passwordFormatModalOpen = action.payload
        },
        setCardLimitModalOpen: (state, action) => {
            state.cardLimitModalOpen = action.payload
        },
        setLogoutModalOpen: (state, action) => {
            state.logoutModalOpen = action.payload
            state.notificationModalOpen = false
        },
        setDropDownCard: (state, action) => {
            state.dropDownCard = action.payload
        },
        setMasterDropdownCard: (state, action) => {
            state.masterDropdownCard = action.payload
        }
    },
})

export default globalSlice.reducer
export const { 
    setNotificationModalOpen, 
    setPasswordFormatModalOpen, 
    setCardLimitModalOpen,
    setLogoutModalOpen,
    setDropDownCard,
    setMasterDropdownCard
} = globalSlice.actions