//auth
export const LOGIN = "/api/auth/login"
export const LOGOUT = "/api/auth/logout"
export const REGISTER = "/api/auth/register"
export const FORGOT_PASSWORD = "/api/auth/forgot-password"
export const RESET_PASSWORD = "/api/auth/reset-password"

//data
export const DAILY_SALES = "/api/sales-detail"
export const DAILY_STORE = "/api/dailyStore"
export const DAILY_TRANSACTION = "/api/dailyTransaction"
export const WEEKLY_SALES = "/api/weekly"
export const LABOR_VARIANCE = "/api/labour"
export const ROLE = "/api/role"
export const PERMISSION = "/api/permission"
export const ENTITY = "/api/entity"
export const MASTER = "/api/master"
export const MASTER_UNIT = "/api/unit"
export const MASTER_PROPERTY = "/api/prop-code"
export const MASTER_WORR = "/api/worr"
export const MASTER_GL_CODE = "/api/gl-code"
export const PROP_WISE_UNIT = "/api/unit/property-code"


export const masterGetAPIEndpoints = {
    0: '/api/prop-code',
    1: 'api/cc-description',
    2: '/api/unit',
    3: '/api/gl-code',
    4: "/api/prop-code?businessType=CJ",
    5: "/api/unit?businessType=CJ",
  };
export const ROLE_PERMISSION = "/api/role-permission"
export const RECEIPT_WALLET = "/api/upload"
export const USER = "/api/user"
export const USER_APPROVER = "/api/user-approver"
export const USER_ACCOUNT = "/api/user-account"

//cc
export const UPLOAD_EXCEL = "/api/upload/excel"
export const BULK_GL_UPLOAD = "/api/gl-code/bulk-upload"
export const PROP_CODE_BULK = "/api/prop-code/bulk-prop-unit"
export const EXTRACT_CC_DETAILS = "/api/cc-details/temp-cc-details"
export const ADMIN_CC_DETAILS = "/api/cc-details"
export const APPROVER_CC_DETAILS = "/api/cc-details/by-status"
export const USER_CC_DETAILS = "/api/cc-details/by-account"
export const UPDATE_CC_DETAILS = "/api/cc-details/cc-details"
export const ACCOUNT_DETAILS = "/api/account-details"
export const VIEW_LOGS_DATA = "/api/upload"
export const UPDATE_APPROVAL_STATUS = "/api/cc-details/update-status"
export const PENDING_BY_APPROVER = "/api/cc-details/pending-by-approver"
export const PENDING_BY_USER = "/api/cc-details/pending-by-user"
export const TOTAL_AMOUNT = "/api/cc-details/total-amount"
export const UPLOAD_PDF_ZIP = "/api/pdf-read"
export const CARD = "/api/card"
export const EMAIL_CONFIGURATION = "/api/notification-settings"
export const EMAIL_REMAINDER = "/api/email-reminder"
export const NOTIFICATION_BELL = "/api/notification"
export const COMMENT = "/api/comment"
export const USER_REPORT = "/api/reports/user-report"
export const APPROVER_REPORT = "/api/reports/approver-report"
export const REJECTED_REPORT = "/api/reports/transaction-report?status=REJECTED&excel=true"
export const SSR_REPORT = "/api/reports/account-transaction-report"
export const USER_DETAILED_REPORT = `/api/reports/user-report-details/`
export const USER_REPORT_DATA = "/api/reports/user-report-details"
export const APPROVER_REPORT_DATA = "/api/reports/approver-report-details"
export const REJECTED_REPORT_DATA = "/api/reports/rejected-transaction-report"
export const NOTIFY = "/api/cc-details/checkbox"
export const EXPORT_FINAL_REPORT = "/api/cc-details/report"
export const RECEIPT_WALLET_NEW = "/api/receipt-wallet"
export const EXPORT_FINAL_CSV = "/api/reports/csv"
export const API_APP_LOGGER = "/api/app-logger/api-logger"
export const ERROR_APP_LOGGER = "/api/app-logger/error-logger"