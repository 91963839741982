import toast from "components/shared/toast/toast"
import { entityNames, tempPagesArray } from "./constants"

export const combineClasses = (...classes) => {
    return classes.join(" ")
}

export const getToken = () => {
    return localStorage.getItem("token")
}

export const dateConverter = (str) => {
    const temp = new Date(str)
    const d = new Date(temp.getTime() + temp.getTimezoneOffset() * 60000)
    let mm = d.getMonth() + 1;
    let dd = d.getDate();

    return [
        (mm>9 ? '' : '0') + mm,
        (dd>9 ? '' : '0') + dd,
        d.getFullYear(),
    ].join('/');
}

export const timeConverter = (str) => {
    const temp = new Date(str)
    const d = new Date(temp.getTime() 
    // + temp.getTimezoneOffset() * 60000
    )
    let ampm;
    let hrs = d.getHours()
    ampm = hrs >= 12 ? "PM" : "AM"
    if(hrs > 12) hrs %= 12
    if(hrs < 10) hrs = "0" + hrs
    let min = d.getMinutes()
    if(min < 10) min = "0" + min
    return `${hrs}:${min} ${ampm}`
}

export const processArray = async (arr, baseUrl) => {
    for (let i = 0; i < arr.length; i++) {
        const url = baseUrl ? baseUrl + arr[i] : arr[i];

        if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.download = true;
            link.click();
            await new Promise((res) => {
            setTimeout(() => {
                res();
            }, 1000);
            });
        }
    }
};

export const getCurrentUser = (key) => {
    const data = JSON.parse(localStorage.getItem("currentUser"))
    switch(key) {
        case "token":
            return data?.getToken
        case "permission":
            return data?.permission
        case "roleId":
            return data?.role
        case "roleName":
            return data?.roleName
        case "userId":
            return data?.userId
        case "isOnlyUser":
            return data?.isOnlyUser
        default:
            return data
    }
}

export const requiredMessage = (name = "This field") => {
    return `${name} is required!`
}

export const convertToPermissionsObj = (allEntity, roleWisePermissions) => {
    return (
        allEntity.length && allEntity.reduce((acc, entity) => {
            if(entity.permission.length) {
                let flag = false
                acc[entity.entityName] = entity.permission.reduce((acc1, permission) => {
                    acc1[permission.actionName] = roleWisePermissions.includes(permission.id)
                    if(roleWisePermissions.includes(permission.id)) {
                        flag = true
                    }
                    return acc1
                }, {})
                acc[entity.entityName].isSelected = flag
            }
            return acc
        }, {})
    )
}

export const checkEntityPermission = (path) => {
    const loggedInPermissions = getCurrentUser("permission")?.reduce((acc, permission) => {
        if(!acc.includes(permission.entityName)) {
            acc.push(permission.entityName)
        }
        return acc
    }, [])
    const splitPath = "/" + path.split("/")[1]
    const entityName = entityNames[splitPath]
    if(typeof(entityName) === "object") {
        let flag = true
        for(let name of entityName) {
            if(!loggedInPermissions?.includes(name)) flag = false
        }
        return flag
    }else {
        return loggedInPermissions?.includes(entityName) || tempPagesArray.includes(path)
    }
}

export const checkPermission = (permissionName) => {
    const permissions = getCurrentUser("permission")
    return permissions.length && permissions.find((permission) => permission.permissionName === permissionName)
}

export const onlyNumbersInput = (e) => {
    ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
}

export const getDefaultDates = (monthsGap = 1) => {
    // const now = new Date("2023/11/14")
    const now = new Date()
    let month = now.getDate() > 15 ? now.getMonth() + 1 : now.getMonth()
    let year = now.getFullYear()
    if(month <= 0) {
        year--
        month = 12 + month
    }
    // if(month < 10) {
    //     month = "0" + month
    // }
    // return {
    //     startDate: `${year}-${(month-1 < 10 ? "0" : "") + (month - 1)}-14`,
    //     endDate: `${year}-${(month < 10 ? "0" : "") + month}-15`,
    // }
    let startMonth = month - monthsGap
    let startYear = year
    if(startMonth < 1) {
        startMonth += 12
        startYear--
    }

    return {
        startDate: `${startYear}-${(startMonth < 10 ? "0" : "") + (startMonth)}-14`,
        endDate: `${year}-${(month < 10 ? "0" : "") + month}-15`,
    }

}

// export const download = (url) => {
//     var element = document.createElement("a");
//     var file = new Blob(
//       [url],
//       { type: url.endsWith(".pdf") ? "application/pdf" : "image/*" }
//     );
//     element.href = URL.createObjectURL(file);
//     // element.href = URL.createObjectURL(file);
//     element.download = url.split("/").slice(-1)[0];
//     element.click();
// }

export const download = async(originalImage) => {
    const image = await fetch(originalImage);

    // Split image name
    const nameSplit = originalImage.split("/");
    const  duplicateName = nameSplit.pop();

    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    toast.success("Downloaded Successfully")
};

export const capitalizeText = (text) => {
    if(!text) return ""
    return text?.split(" ")?.map(x => x[0].toUpperCase() + x.slice(1))?.join(" ")
}

export const checkImageAndPdfFormat = (file) => {
    if(!file) return
    return file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'application/pdf'
}

export const getInstructionPdfPath = (type = "manual") => {
    const role = localStorage.getItem("currentRole")
    switch(role) {
        case "user":
            return type === "manual" ? 
            "http://103.165.118.9:5025/uploads/manual/CC%20-%20User%20Manual.pdf" :
            { src: "http://103.165.118.9:5025/uploads/manual/CC%20-%20User.mp4", type: 'video/mp4' }
        case "approver":
            return type === "manual" ? 
            "http://103.165.118.9:5025/uploads/manual/CC%20-%20Approver%20Manual.pdf" :
            { src: "http://103.165.118.9:5025/uploads/manual/CC%20-%20Approver.mkv", type: 'video/x-matroska; codecs="theora, vorbis"' }
        default:
            return
    }
}

export const formatFrequencyTiming = (frequencyTiming, format = 12) => {
    const temp = frequencyTiming?.split?.(" ")
    const hrs = +temp?.[1]
    return temp?.length < 2 ?
    frequencyTiming :
    format == 12 ? 
    `${((hrs % 12) + "")?.padStart?.(2, '0')}:${temp[0]?.padStart?.(2, '0')} ${hrs > 11 ? "P" : "A"}M` :
    `${(hrs + "")?.padStart?.(2, '0')}:${temp[0]?.padStart?.(2, '0')}`
}