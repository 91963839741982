import axios from "axios";
import { getToken } from "utils/utils";
import toast from "components/shared/toast/toast";

let unAuthorizedCount = 0

const privateRequest = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})
 
const requestHandler = (request) => {
    const token = getToken() || ""
    const controller = new AbortController();
    if (!token) {
        controller.abort();
    }
    request.headers.Authorization = `Bearer ${token}`
    request.headers['x-user-role'] = localStorage.getItem("currentRole")
    return { ...request, signal: controller.signal}
}

const clearToken = () => {
    localStorage.removeItem("token")
}

const responseErrorHandler = (error) => {
    if(error.response){
        const {status, data, message} = error.response
        
        switch (status) {
            case 401:
                if(!unAuthorizedCount) {
                    unAuthorizedCount++
                    clearToken()
                    toast.error("Session expired, please login again!")
                    setTimeout(() => {
                        unAuthorizedCount = 0
                        window.location.href = "/"
                    }, 300)
                }
                break;
            case 400:
                toast.error(data.message || data?.error || message || "Invalid Value/ Bad Request")
                break;
            case 403:
                toast.error(data.message || data?.error || message || "Access Denied/ Forbidden")
                break;
            case 404:
                // toast.error(data.message || data?.error || message || "Item doesn't exist")
                break;
            case 405:
                toast.error(data.message || data?.error || message || "Invalid Request")
                break;
            case 422:
                toast.error(data.message || data?.error || message || "Already Exists")
                break;
            case 504:
                toast.error(data.message || data?.error || message || "Network Error")
                break;
            default:
                toast.error(data.message || data?.error || message || "Some Error Occurred")
                break;
        }
    } else if (error?.code === "ERR_CANCELED") {
        return
    }
    else{
        toast.error(error?.message || "Some Error Occurred")
    }
}

const errorHandler = (error) => {
    return Promise.reject(error)
}

privateRequest.interceptors.request.use(
    requestHandler,
    errorHandler
)

privateRequest.interceptors.response.use(
    response => response,
    responseErrorHandler
)

export const privateGet = (endPoint, config = {}) => {
    // Object.entries(config.params || {}).forEach(([key, value]) => {
    //     if(!value) {
    //         delete config.params[key]
    //     }
    // })
    return privateRequest.get(`${endPoint}`, config)
}

export const privatePost = (endPoint, data, config = {}) => {
    return privateRequest.post(endPoint, data, config)
}

export const privatePut = (endPoint, id, data, config = {}) => {
    return privateRequest.put(`${endPoint}${id ? `/${id}`: ""}`, data, config)
}

export const privatePatch = (endPoint, id, data) => {
    return privateRequest.put(`${endPoint}/${id}`, data)
}

export const privateDelete = (endPoint, id) => {
    return privateRequest.delete(`${endPoint}/${id}`)
}

export default privateRequest