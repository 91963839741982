import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { PERMISSION, ROLE } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";

const initialState = {
    currentRole: "",
    roles: [],
    selectedRole: null,
    totalRoles: 0,
    permissions: [],
    allPermissions: [],
    roleWisePermissions: [],
    totalPermissions: 0,
    selectedPermission: null,
    permissionModalOpen: false,
    isFetching: false,
    loggedInPermissions: []
}

// roles
export const getRolesData = createAsyncThunk("roles/getRolesData", async(params) => {
    const res = await privateGet(ROLE, { params })
    return {
        data: res?.data?.data || [],
        totalRoles: res?.data?.count || 0
    }
})

export const getRoleById = createAsyncThunk("roles/getRoleById", async(id) => {
    const res = await privateGet(`${ROLE}/${id}`)
    return {
        data: res?.data?.data || null,
    }
})

export const deleteRole = createAsyncThunk("roles/deleteRole", async(id, { dispatch }) => {
    const res = await privateDelete(ROLE, id)
    if (res) {
        dispatch(getRolesData())
        toast.success("Role deleted successfully")
    }
})

// permissions
export const getPermissionsData = createAsyncThunk("roles/getPermissionsData", async(params) => {
    const res = await privateGet(PERMISSION, { params })
    return {
        data: res?.data?.data || [],
        totalPermissions: res?.data?.count || 0,
    }
})

export const getAllPermissionsData = createAsyncThunk("roles/getAllPermissionsData", async() => {
    const res = await privateGet(PERMISSION)
    return {
        data: res?.data?.data || [],
    }
})

export const getRoleWisePermissions = createAsyncThunk("roles/getRoleWisePermissions", async({roleId, params}) => {
    const res = await privateGet(`/api/role-permission/${roleId || ""}`, { params })
    return {
        data: res?.data?.data?.length ? res?.data?.data?.map(x => x.permissionId) : [],
    }
})

export const deletePermission = createAsyncThunk("roles/deletePermission", async(id, { dispatch }) => {
    const res = await privateDelete(PERMISSION, id)
    if(res) {
        dispatch(getPermissionsData())
        toast.success("Permission deleted successfully")
    }
})

const rolesSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedRole: (state, action) => {
            state.selectedRole = action.payload
            if(!action.payload) {
                state.roleWisePermissions = []
            }
        },
        setSelectedPermission: (state, action) => {
            state.selectedPermission = action.payload
            state.permissionModalOpen = !!action.payload
        },
        setPermissionModalOpen: (state, action) => {
            state.permissionModalOpen = action.payload
        },
        setLoggedInPermissions: (state, action) => {
            state.loggedInPermissions = action.payload
        },
        setCurrentRole: (state, action) => {
            state.currentRole = action.payload
        }
    },
    extraReducers: {
        [getRolesData.pending]: (state) => {
            state.isFetching = true
        },
        [getRolesData.fulfilled]: (state, action) => {
            state.roles = action.payload.data
            state.totalRoles = action.payload.totalRoles
            state.isFetching = false
        },
        [getRolesData.rejected]: (state) => {
            state.isFetching = false
        },
        [getRoleById.fulfilled]: (state, action) => {
            state.selectedRole = action.payload.data
        },
        [getPermissionsData.pending]: (state) => {
            state.isFetching = true
        },
        [getPermissionsData.fulfilled]: (state, action) => {
            state.permissions = action.payload.data
            state.totalPermissions = action.payload.totalPermissions
            state.isFetching = false
        },
        [getPermissionsData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllPermissionsData.fulfilled]: (state, action) => {
            state.allPermissions = action.payload.data
        },
        [getRoleWisePermissions.fulfilled]: (state, action) => {
            state.roleWisePermissions = action.payload.data
        },
    }
})

export default rolesSlice.reducer
export const { 
    setIsFetching, 
    setSelectedRole, 
    setSelectedPermission, 
    setPermissionModalOpen, 
    setLoggedInPermissions, 
    setCurrentRole
} = rolesSlice.actions