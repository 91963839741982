import React, { Fragment, memo, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { PATH, dontShowInstructionsKey } from "utils/constants";
// import { PAGENAME } from "utils/constants";
import styles from "./HomeLayout.module.scss";
// import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
// import arrow from "assets/png/arrow.png";
import toast from "components/shared/toast/toast";
import Button from "components/shared/button/Button";
import { privateGet, privatePost } from "services/privateRequest";
import { LOGOUT } from "services/apiEndpoints";
import { useDispatch, useSelector } from "react-redux";

//image imports
// import logout from "assets/png/logout.png";
import profilePic from "assets/png/default-profile.png";
import notificationIcon from "assets/svg/fi-sr-bell.svg";
import { ReactComponent as LeftArrow } from "assets/svg/arrow-left.svg";
import { ReactComponent as MenuIcon } from "assets/svg/menu.svg";
import { clear, setSidebarOpen } from "redux/slices/homeSlice";
import { setLogoutModalOpen, setNotificationModalOpen } from "redux/slices/globalSlice";
import { ReactComponent as MoreIcon } from "assets/svg/more-vertical.svg"
import Notifications from "./Notifications";
import axios from "axios";
import { getToken, getCurrentUser } from "utils/utils";
import { clearAccounts } from "redux/slices/accountsSlice";

const TopBar = () => {
  const [currentPath, setCurrentPath] = useState({});
  const [unread, setUnread] = useState(false);

  const { userDetailsPageName } = useSelector((state) => state.userDetails);
  const { notificationModalOpen, logoutModalOpen } = useSelector((state) => state.global);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentRole = getCurrentUser("roleName")?.toLowerCase()

  const email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "user@gmail.com";

  const backToPrevious = () => {
    navigate(-1);
  };

  const removeLastEndpoint = (str) => str.split("/").slice(0, -1).join("/");

  const pageName = useMemo(() => {
    let temp = {};
    const obj = Object.values(PATH.privateRoutes).find((pathData) => {
      return (
        pathData.path === location.pathname ||
        (pathData.path.endsWith("Id") &&
          removeLastEndpoint(pathData.path) ===
            removeLastEndpoint(location.pathname))
      );
    });
    if (obj) {
      temp = obj;
    }
    setCurrentPath(temp);
    return temp?.pageName || "";
    // switch (pathName) {
    //   case PATH.BALANCE_SHEET || "/balance-generate":
    //     return PAGENAME.BALANCESHEET + " - Report Logs";

    //   case PATH.CASHFLOW_SHEET:
    //     return PAGENAME.CASHFLOWSHEET + " - Report Logs";

    //   case PATH.CONFIGURE:
    //     return PAGENAME.CONFIGURE;

    //   case PATH.BALANCE_GENERATE:
    //     return PAGENAME.BALANCESHEET + " - Report Logs";

    //   case PATH.CASHFLOW_GENERATE:
    //     return PAGENAME.CASHFLOWSHEET + " - Report Logs";

    //   default:
    //     break;
    // }
  }, [location]);

  const clearOnLogout = async(e) => {
    e.stopPropagation()
    try {
      await axios.get(LOGOUT, {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }
    catch(err) {}
    finally {
      localStorage.clear();
      (currentRole === "user" || currentRole === "approver") && localStorage.setItem(dontShowInstructionsKey, true)
      // dispatch(clear()) // uncomment this line if you want to clear redux store
      dispatch(clearAccounts())
      dispatch(setLogoutModalOpen(false))
      toast.success("Logged out successfully")
      navigate("/login")
    }
  }

  const openSidebarForSmall = () => {
    dispatch(setSidebarOpen(true));
  };

  return (
    <Fragment>
      <div className={styles.topBarContainer}>
        <div className={styles.leftSide}>
          <MenuIcon className={styles.menuIcon} onClick={openSidebarForSmall} />
          {(userDetailsPageName && userDetailsPageName !== "Receipt Wallet") || (currentPath?.showBackIcon && currentPath?.path !== "/receipt-wallet/:userId") ? (
            <LeftArrow className={styles.backIcon} onClick={backToPrevious} />
          ) : (
            ""
          )}
          <p className={styles.pageName}>
            {pageName || userDetailsPageName || ""}
          </p>
        </div>

        <div className={styles.rightSide}>
          {/* {currentPath?.showGenerateBtn && (
            <Button
              className={styles.generate}
              onClick={() => navigate(location.pathname + "/generate")}
              navigateTo={props?.navigateTo}
              label="Generate"
            />
          )} */}
          <div className={styles.notificationIconContainer}>
            {/* <img
              className={styles.notificationIcon}
              src={notificationIcon}
              alt="notification icon"
              onClick={(e) => {
                e.stopPropagation()
                dispatch(setNotificationModalOpen(!notificationModalOpen));
              }}
            />
            {unread ? <div className={styles.unread}></div> : ""}
            {notificationModalOpen && (
              <Notifications />
            )} */}
          </div>

          <div className={styles.logoutCircle}>
            {/* <span
              onClick={(e) => {
                e.stopPropagation();
                setLogoutModel(!logoutModel);
              }}
              className={styles.firstLetter}
            >
              {email.charAt(0).toUpperCase()}
            </span> */}
            <img
              className={styles.profilePic}
              src={profilePic}
              alt="profile pic of the logged in user"
            />
            <span className={styles.userName}>{email}</span>
            <MoreIcon 
              className={styles.moreIcon}
              onClick={(e) => {
                // e.stopPropagation()
                dispatch(setLogoutModalOpen(!logoutModalOpen))
              }}
            />
            {logoutModalOpen && (
              <div className={styles.logoutModal} onClick={clearOnLogout}>
                Logout
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default memo(TopBar);
