import React from 'react'
import ReactSelect from 'react-select';
import styles from "./Select.module.scss"
import { ErrorMessage, Field } from 'formik';
import ErrorText from 'components/shared/errortext/ErrorText';

const Select = (props) => {
    const { 
        label, 
        name, 
        placeholder, 
        isRequired, 
        isDisabled, 
        options, 
        formik, 
        optionsHeight,
        fieldHeight,
        isMulti, 
        error, 
        onChange,
        isClearable = false,
        divHeight,
        ...rest 
    } = props

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: 'none',
            //   borderColor: '#9e9e9e',
            border: "none",
            minHeight: '28px',
            height: fieldHeight || (isMulti ? "maxContent" : '28px'),
            boxShadow: null,
            overflow: isMulti ? "auto !important" : "hidden",
            cursor: "pointer",
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: fieldHeight || (isMulti ? "maxContent" : '28px'),
            // padding: '0 8px',
            maxHeight: "50px",
            overflowY: "auto"
        }),

        menuList: (provided) => ({
            ...provided,
            maxHeight: optionsHeight || "100px",
            overflowX: "hidden",
        }),

        option: (provided) => ({
            ...provided,
            cursor: "pointer"
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            padding: "0",
            fontWeight: "600 !important"
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: fieldHeight || (isMulti ? "maxContent" : '28px'),
        }),
    };

    return (
        <div className={styles.inputControl} style={{ height: divHeight || "80px" }}>
            {label && <label className={styles.label} htmlFor={name}>{label} {
                isRequired ?
                    <span className={styles.requiredMark}>*</span>
                    : ""}
            </label>}
            {
            formik ?
            <Field name={name}>
                {
                    ({ field }) => {
                        const valuesArray = isMulti && field?.value?.map(x => +x.value)
                        return (
                            <ReactSelect
                                {...rest}
                                {...field}
                                {...rest?.fieldRef ? { ref: rest?.fieldRef } : {}}
                                id={name}
                                className={styles.inputField}
                                options={isMulti ? options?.filter(x => !valuesArray?.includes(+x.value)): options}
                                styles={customStyles}
                                onChange={(value) => {
                                    formik.setFieldValue(name, value)
                                    onChange?.(value)
                                }}
                                placeholder={placeholder}
                                classNamePrefix="react-select"
                                isDisabled={isDisabled}
                                isMulti={isMulti}
                                menuPlacement={"auto"}
                                // closeMenuOnSelect={!isMulti}
                                isClearable={isMulti || isClearable}
                            />
                        )
                    }
                }
            </Field>
            :
            <ReactSelect
                {...rest}
                id={name}
                className={styles.inputField}
                onChange={onChange || (() => {})}
                options={options}
                styles={customStyles}
                placeholder={placeholder}
                classNamePrefix="react-select"
                isDisabled={isDisabled}
                isMulti={isMulti}
            />
            }
            {formik && !error ? 
            <ErrorMessage
                name={name}
                component={ErrorText}
            />
            :
            <ErrorText>
                {error}
            </ErrorText>
            }
        </div>
    )
}

export default Select;