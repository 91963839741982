import React, { useState } from "react";
import styles from "./OtpVerification.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "components/shared/toast/toast";
import { emailRegex, passwordRegex } from "utils/regex";
import { LOGIN, RESET_PASSWORD } from "services/apiEndpoints";
import publicRequest from "services/publicRequest";
import LoginLayout from "components/layouts/loginlayout/LoginLayout";
import * as Yup from "yup"
import { combineClasses, requiredMessage } from "utils/utils";
import { Form, Formik } from 'formik';
import FormikControl from "components/formikcontrols/FormikControl";
import Button from 'components/shared/button/Button';
import { PATH, passwordInstructions } from "utils/constants";
import { ReactComponent as VerifiedIcon } from "assets/svg/verified.svg"
import { ReactComponent as BackIcon } from "assets/svg/arrow-left.svg"
import LoadingText from "components/shared/loadingtext/LoadingText";

const VerifyPassword = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { id } = useParams()

    const initialValues = {
        password: "",
        confirm: "",
    }

    const validationSchema = Yup.object({
        password: Yup.string()
            .required(requiredMessage("Password"))
            .min(6, "Password must be at least 6 characters ")
            .max(25, "Password name must be at most 25 characters "),
        confirm: Yup.string()
            .required(requiredMessage("Password"))
            .when("password", ([password], schema) => {
                return schema.test("passwordMatch", "Passwords don't match!", (value) => {
                    return value === password
                })
            })
    })

    // const forgotPassword = (values) => {
    //     return publicRequest.post(FORGOT_PASSWORD, values)
    //   }

    const resetPassword = (values) => {
        return publicRequest.post(RESET_PASSWORD, values)
    }

    const handleSubmit = async (values) => {
        const obj = {
            id: id,
            ...values
        }
        const p = resetPassword(obj)
        await toast.promise(p, {
            loading: <>Resetting password<LoadingText style={{ alignSelf: "flex-end", paddingBottom: "2px" }} /></>,
            success: () => "Reset Password Successfully",
            error: () => "Invalid Password",
        })
        navigate(PATH.publicRoutes.LOGIN.path)
    };

    return (
        <LoginLayout>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => {
                    return (
                        <div className={styles.mainDiv}>
                            <BackIcon 
                                onClick={() => navigate(PATH.publicRoutes.FORGOT_PASSWORD.path)} 
                                className={styles.backIcon}
                            />
                            <p className={styles.heading}>Reset Password</p>
                            <p className={styles.subHeading}>{location?.state?.email} 
                            {/* <VerifiedIcon className={styles.verifiedIcon} /> */}
                            </p>
                            <Form className={styles.form} autoComplete="off">
                                <FormikControl
                                    name="password"
                                    control="password"
                                    type="password"
                                    label="Password"
                                    placeholder="Enter Password"
                                    isRequired={true}
                                />
                                <FormikControl
                                    name="confirm"
                                    control="password"
                                    type="password"
                                    label="Re-enter Password"
                                    placeholder="Enter Password"
                                    isRequired={true}
                                />
                                <Button
                                    type="submit"
                                    className={styles.button}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Form>
                            {/* <div className={styles.passwordNotes}>
                                <strong>Note: </strong>
                                Password must -
                                <ul>
                                    {passwordInstructions.map(instruction => (
                                        <li>{instruction}</li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                    )
                }}
            </Formik>
        </LoginLayout>
    );
};

export default VerifyPassword;
