import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import store from 'redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Toaster 
      position="top-right" 
      reverseOrder={false}
      toastOptions={{
        style: {
          fontFamily: "Poppins",
          fontSize: "14px"
        }
      }} 
    />
    <Provider store={store}>
      <App />
    </Provider>
  </>
);
