import Modal from 'components/shared/modal/Modal';
import { Form, Formik } from 'formik';
import React from 'react';
import { requiredMessage } from 'utils/utils';
import * as Yup from "yup";
import FormikControl from 'components/formikcontrols/FormikControl';
import styles from "./RejectionModal.module.scss";
import Button from 'components/shared/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setRejectedModalOpen } from 'redux/slices/userDetailsSlice';

const RejectionModal = ({ modalOpen, setModalOpen, handleReject, handleSendFromRejected, checkedArray }) => {
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(setRejectedModalOpen(false));
        setModalOpen();
    }

    const initialValues = {
        reason: "",
    }

    const validationSchema = Yup.object({
        reason: Yup.string()
            .required(requiredMessage(handleSendFromRejected ? "Comment" : "Reason"))
            .max(150, handleSendFromRejected ? "Comment for rejection should be less than 150 characters" : "Reason for rejection should be less than 150 characters")
    });

    const handleSubmit = async(values, { resetForm }) => {
        if (handleSendFromRejected) {
            handleSendFromRejected(values.reason);
        } else {
            handleReject(values.reason, modalOpen);
        }
        setModalOpen(false);
        resetForm();
    }

    return (
        <Modal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            heading={handleSendFromRejected ? "Comment On Rejection" : "Reason for Rejection"}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <Form className={styles.form}>
                    <FormikControl
                        name="reason"
                        control="textarea"
                        label=""
                        placeholder={"Enter " + (handleSendFromRejected ? "Comment" : "Reason")}
                        className={styles.reasonTextarea}
                    />
                    <span className={styles.buttonsContainer}>
                        <Button
                            className={styles.cancelButton}
                            onClick={closeModal}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">
                            Submit
                        </Button>
                    </span>
                </Form>
            </Formik>
        </Modal>
    );
}

export default RejectionModal;