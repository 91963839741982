import React, { useState } from "react";
import styles from "./Register.module.scss";
import { useNavigate } from "react-router-dom";
import toast from "components/shared/toast/toast";
import { alphabetsRegex, emailRegex, emailRegexDomain, mobileRegex, numberRegex } from "utils/regex";
import { REGISTER } from "services/apiEndpoints";
import publicRequest from "services/publicRequest";
import LoginLayout from "components/layouts/loginlayout/LoginLayout";
import * as Yup from "yup"
import { onlyNumbersInput, requiredMessage } from "utils/utils";
import { Form, Formik } from 'formik';
import FormikControl from "components/formikcontrols/FormikControl";
import Button from 'components/shared/button/Button';
import { PATH } from "utils/constants";

const Register = () => {

    const navigate = useNavigate();

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: ""
    }

    const validationSchema = Yup.object({
        email: Yup.string()
            .required(requiredMessage("Email ID"))
            .matches(emailRegex, "Invalid email format!")
            .matches(emailRegexDomain, "Only .org, .com, .in, .edu domains accepted!"),
        firstName: Yup.string()
            .required(requiredMessage("First Name"))
            .matches(alphabetsRegex, "Only letters allowed!")
            .min(2, "Minimum 2 characters required")
            .max(25, "Maximum 25 characters allowed!"),
        lastName: Yup.string()
            .required(requiredMessage("Last Name"))
            .matches(alphabetsRegex, "Only letters allowed!")
            .min(2, "Minimum 2 characters required")
            .max(25, "Maximum 25 characters allowed!"),
        mobileNumber: Yup.string()
            .required(requiredMessage("Mobile Number"))
            .matches(mobileRegex, "Invalid mobile number format!"),
    })

    const register = (values) => {
        return publicRequest.post(REGISTER, values).then((res) => {
            // localStorage.setItem("token", res?.data?.data)
            // localStorage.setItem("role", 'admin')
            // localStorage.setItem("email", values.email)
            navigate(PATH.publicRoutes.LOGIN.path)
        })
    }

    const handleSubmit = async (values) => {
        const p = register(values)
        toast.promise(p, {
            loading: "Regsitering user...",
            success: () => "User Registered Successfully",
            error: () => "Some error occured",
        })
    };

    return (
        <LoginLayout>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => {
                    return (
                        <div className={styles.mainDiv}>
                            <p className={styles.heading}>Welcome,</p>
                            <p className={styles.subHeading}>Let's sign up here</p>
                            <Form className={styles.form} autoComplete="off">
                                <FormikControl
                                    name="firstName"
                                    control="input"
                                    label="First Name"
                                    placeholder="Enter First Name"
                                    isRequired={true}
                                />
                                <FormikControl
                                    name="lastName"
                                    control="input"
                                    label="Last Name"
                                    placeholder="Enter Last Name"
                                    isRequired={true}
                                />
                                <FormikControl
                                    name="email"
                                    control="input"
                                    label="Email ID"
                                    placeholder="Enter Email ID"
                                    isRequired={true}
                                />
                                <FormikControl
                                    name="mobileNumber"
                                    control="input"
                                    label="Mobile Number"
                                    placeholder="Enter Mobile Number"
                                    isRequired={true}
                                    type="number"
                                    onChange={({ target: { value } }) => {
                                        if(value === "" || value.length <= 10) {
                                            formik.setFieldValue("mobileNumber", value)
                                        }
                                    }}
                                    onKeyPress={onlyNumbersInput}
                                />
                                <Button
                                    type="submit"
                                    className={styles.button}
                                    disabled={formik.isSubmitting}
                                >
                                    Register
                                </Button>
                                <p className={styles.bottomText}>
                                    Already have an account? <span
                                        className="link-text"
                                        onClick={() => navigate(PATH.publicRoutes.LOGIN.path)}
                                    >
                                        Sign in
                                    </span>
                                </p>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </LoginLayout>
    );
};

export default Register;