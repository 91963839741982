import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { ENTITY } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";

const initialState = {
    entity: [],
    totalEntity: 0,
    selectedEntity: null,
    entityModalOpen: false,
    isFetching: false,
    allEntity: [],
}

// Entity
export const getEntityData = createAsyncThunk("entity/getEntityData", async(params) => {
    const res = await privateGet(ENTITY, { params })
    return {
        data: res?.data?.data || [],
        totalEntity: res?.data?.count || 0
    }
})

export const getAllEntityData = createAsyncThunk("entity/getAllEntityData", async(params) => {
    const res = await privateGet(ENTITY, { params })
    return {
        data: res?.data?.data || [],
    }
})

export const deleteEntity = createAsyncThunk("entity/deleteEntity", async(id, { dispatch }) => {
    await privateDelete(ENTITY, id)
    dispatch(getEntityData())
    toast.success("Entity deleted successfully")
})

const entitySlice = createSlice({
    name: "entity",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedEntity: (state, action) => {
            state.selectedEntity = action.payload
            state.entityModalOpen = !!action.payload
        },
        setEntityModalOpen: (state, action) => {
            state.entityModalOpen = action.payload
        }
    },
    extraReducers: {
        [getEntityData.pending]: (state) => {
            state.isFetching = true
        },
        [getEntityData.fulfilled]: (state, action) => {
            state.entity = action.payload.data
            state.totalEntity = action.payload.totalEntity
            state.isFetching = false
        },
        [getEntityData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllEntityData.fulfilled]: (state, action) => {
            state.allEntity = action.payload.data
        },
    }
})

export default entitySlice.reducer
export const { setIsFetching, setSelectedEntity, setEntityModalOpen } = entitySlice.actions