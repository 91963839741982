import React, { Suspense, memo, useEffect, useState } from "react";
import styles from "./HomeLayout.module.scss";
import { Outlet } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import TopBar from "./TopBar";
import CircleSpinner from "components/shared/loader/CircleSpinner";
import {
  setCurrentRole,
  setLoggedInPermissions,
} from "redux/slices/rolesAndPermissionSlice";
import { combineClasses, getCurrentUser } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarOpen } from "redux/slices/homeSlice";
import { setDropDownCard, setLogoutModalOpen, setMasterDropdownCard, setNotificationModalOpen } from "redux/slices/globalSlice";

const HomeLayout = () => {

  const { sidebarOpen } = useSelector(state => state.home)
  const { notificationModalOpen, logoutModalOpen } = useSelector(state => state.global)
  const dropDownCard = useSelector(state => state.global.dropDownCard);
  const masterDropdownCard = useSelector(state => state.global.masterDropdownCard);

  const dispatch = useDispatch();

  const toggleSidebarOpen = (flag) => (e) => {
    e.stopPropagation();
    setSidebarOpen !== flag && dispatch(setSidebarOpen(flag));
  };

  // const getCurrentPermissions = async(roleId) => {
  //     const entityRes = await privateGet(ENTITY)
  //     const permissionRes = await privateGet(`/api/role-permission/${roleId}`)
  //     dispatch(setLoggedInPermissions(convertToPermissionsObj(
  //         entityRes?.data?.data || [],
  //         permissionRes?.data?.data?.length ? permissionRes.data.data.map(x => x.id) : []
  //     )))
  // }

  const getCurrentPermissions = async () => {
    // const entityRes = await privateGet(ENTITY)
    // const permissionRes = await privateGet(`/api/role-permission/${roleId}`)
    const temp = getCurrentUser("permission")?.reduce((acc, permission) => {
      // if(acc[permission.entityId]) {
      //     acc[permission.entityId][permission.actionName] = true
      // } else {
      //     acc[permission.entityId] = {
      //         [permission.actionName] : true
      //     }
      // }
      if (!acc.includes(permission.entityName)) {
        acc.push(permission.entityName);
      }
      return acc;
    }, []);
    dispatch(setLoggedInPermissions(temp));
    dispatch(setCurrentRole(localStorage.getItem("currentRole")));
  };

  useEffect(() => {
    getCurrentPermissions();
  }, []);

  return (
    <div
      className={styles.layout}
      onClick={(e) => {
        // e.stopPropagation();
        notificationModalOpen && dispatch(setNotificationModalOpen(false));
        logoutModalOpen && dispatch(setLogoutModalOpen(false))
        dropDownCard && dispatch(setDropDownCard(false))
        masterDropdownCard && dispatch(setMasterDropdownCard(false))
      }}
    >
      <LeftSideBar toggleSidebarOpen={toggleSidebarOpen} />
      <div
        className={styles.rightSideContainer}
        onMouseOver={toggleSidebarOpen(false)}
      >
        <TopBar />
        <div className={combineClasses(
          styles.outletDiv,
          sidebarOpen ? styles.sidebarOpen : ""
        )}>
          <Suspense fallback={<CircleSpinner />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default memo(HomeLayout);
