import React, { useEffect, useState, useCallback, useMemo } from "react";
import styles from "./ReceiptWallet.module.scss"
import Button from "components/shared/button/Button";
// import Status from "../../components/Status";
// import { privateGet } from "services/privateRequest";
// import { CASHFLOW_LOGS, LOGS } from "../../services/endPoint";
// import Spinner from "../../components/Spinner";
import Table from "components/shared/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ViewIcon } from "assets/svg/view-icon.svg"
import { ReactComponent as EditIcon } from "assets/svg/edit-icon.svg"
import { ReactComponent as DeleteIcon } from "assets/svg/delete-icon.svg"
import { 
    deleteReceiptWallet, 
    getReceiptWalletData, 
    setSelectedReceiptWallet, 
    setReceiptWalletModalOpen,
} from "redux/slices/receiptWalletSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmDeleteModal from "components/shared/confirmdeletemodal/ConfirmDeleteModal";
import AddReceiptWallet from './AddReceiptWallet';
import { privateGet, privatePost } from "services/privateRequest";
import { RECEIPT_WALLET, RECEIPT_WALLET_NEW, UPLOAD_EXCEL, masterGetAPIEndpoints } from "services/apiEndpoints";
import { combineClasses, dateConverter, getCurrentUser, requiredMessage } from "utils/utils";
import ViewReceipt from "./ViewReceipt";
import toast from "components/shared/toast/toast";
import { PAGINATION, PATH } from "utils/constants";
import { setUserDetailsPageName } from "redux/slices/userDetailsSlice";
import  * as Yup from 'yup';
import { Formik } from "formik";
import ViewAllReceiptDetails from "./ViewAllReceiptDetails";

const ReceiptWallet = () => {
    // local state
    const [propOptions, setPropOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [cjStores, setCjStores] = useState([]);
    const [isFetchingOptions, setIsFetchingOptions] = useState(false);
    const [description, setDescription] = useState("");
    const [rowsPerPageValue, setRowsPerPageValue] = useState(PAGINATION.DEFAULT_ROWS_PER_PAGE);
    const [count, setCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(1);
    const [deleteModalDetails, setDeleteModalDetails] = useState({
        open: false,
        id: null
    });
    const [viewModalDetails, setViewModalDetails] = useState({
        open: false,
        id: null
    });
    // redux state
    const { 
        isFetching, 
        receiptWallet, 
        receiptWalletModalOpen,
        totalReceiptWallet,
     } = useSelector(state => state.receiptWallet)

    const { currentRole } = useSelector(state => state.rolesAndPermission)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userId } = useParams()
    const location = useLocation()

    const user = location?.state?.user

    const initialValues = {
        remarksDetailedExpenseDesc: "",
        propCodeId: "",
        path: [],
        amount: "",
        worr: { label: "-", value: null },
        unitCodeId: [],
    }

    const validationSchema = Yup.object({
        remarksDetailedExpenseDesc: Yup.string().required(requiredMessage("Description")),
        propCodeId: Yup.object().required(requiredMessage("Prop code")),
        amount: Yup.string().test("isNumber", "Only Numbers", (value) => +value).required(requiredMessage("Amount")),
        worr: Yup.object()
        // .required(requiredMessage("WO/RR"))
        ,
        unitCodeId: Yup.array().of(Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        })).min(1, requiredMessage("Unit code")),
        path: Yup.array().of(Yup.string()).min(1, requiredMessage("Receipt")),
    })

    const handleSubmit = async(values, formik) => {
        const payload = {
            ...values,
            unitCodeId: values?.unitCodeId?.map(x => x?.value || "") || [],
            propCodeId: values?.propCodeId?.value || "",
            worr: values?.worr?.value || null,
            amount: +values?.amount || "",
            userId: +userId,
        }

        const res = await privatePost(RECEIPT_WALLET_NEW + "/receipt", payload, { params: { isReceiptWallet: true }})
        if(res) {
            toast.success("Receipt Added Successfully")
            getData()
            dispatch(setSelectedReceiptWallet(false))
            formik?.resetForm?.()
        }
    }

    const propsObj = useMemo(() => {
        return propOptions?.reduce((acc, prop) => {
            acc[prop?.value] = prop?.label
            return acc
        }, {})
    }, [propOptions])

    const getAllPropOptions = async () => {
        if (propOptions?.length) return
        const propRes = await privateGet(masterGetAPIEndpoints[0] + "?all=true")
        setPropOptions(propRes?.data?.data?.map((prop) => ({
            label: `${prop.propCode?.toUpperCase()} ${prop.propCodeFullForm ? `(${prop.propCodeFullForm})` : ""}`,
            value: prop.id
        })))
    }

    const getAllUnitOptions = async () => {
        if (Object.keys(unitOptions || {})?.length || currentRole === "approver") return
        const unitRes = await privateGet(masterGetAPIEndpoints[2] , { params: {
            page: 1,
            limit: 5000,
            // businessType: "RE",
        }})
        const storeRes = await privateGet(masterGetAPIEndpoints[2] , { params: {
            page: 1,
            limit: 5000,
            businessType: "CJ",
        }})
        setCjStores(storeRes?.data?.data?.map((store) => ({
            label: store?.unit || "-",
            value: store.id,
            entity: {
                value: store?.propCodeId,
                label: store?.propCode?.propCode,
            }
        })))
        setUnitOptions(unitRes?.data?.data?.reduce((acc, unit) => {
            acc[unit?.propCodeId] = [...(acc[unit?.propCodeId] || []), {
                label: unit?.unit || "",
                value: unit?.id
            }]
            return acc
        }, {}))
    }

    const getSelectOptions = async () => {
        if (propOptions?.length) return
        setIsFetchingOptions(true)
        await Promise.all([getAllPropOptions(), getAllUnitOptions()])
        setIsFetchingOptions(false)
    }

    const getData = () => {
        setCount(0)
        dispatch(getReceiptWalletData({
            userId,
            params: { page: pageSelected, limit: rowsPerPageValue }
        }))
    }

    const setDeleteModalOpen = (value) => {
        setDeleteModalDetails({
            ...deleteModalDetails,
            open: value
        })
    }

    const handleDelete = (id) => async() => {
        await dispatch(deleteReceiptWallet(id))
        getData()
        setDeleteModalOpen(false)
    }

    const viewReceipt = (row) => () => {
        const id = {
            ...row,
            propUnit: row?.propUnit?.map?.(propUnit => ({
                propCode: propOptions?.find?.(option => option.value == propUnit?.propCodeId),
                unitCode: propUnit.unitCodeId?.map?.(unitId => unitOptions?.[propUnit?.propCodeId]?.find(opt => opt.value === unitId))
            }))
        }
    
        setViewModalDetails({
            open: true,
            id
        })
    } 

    const handleUploadReceipt = async (file) => {
        const formData = new FormData()
        formData.append("files", file)
        description && formData.append("description", description)
        currentRole === "admin" && formData.append("userId", userId)
        const res = await privatePost(UPLOAD_EXCEL + "?isCcReceipt=true", formData)
        if (res) {
            toast.success("Receipt Added Successfully")
            getData()
        }
    }
    
    const column = [
        {
            name: "Receipt",
            width: "200px",
            selector: (row) => (
                <div
                    className={combineClasses(styles.name, 'cursor-auto',)}
                >
                    {row.description || "-"}
                </div>
            ),
        },
        // {
        //     name: "Prop Code",
        //     width: "180px",
        //     selector: (row) => (propsObj?.[row?.propCodeId] || "-"),
        // },
        // {
        //     name: "Unit Code",
        //     width: "150px",
        //     selector: (row) => (row?.unitCodeId?.join(", ") || "-"),
        // },
        {
            name: "WO/RR",
            width: "100px",
            selector: (row) => row?.worr || "-",
        },
        {
            name: "Amount",
            width: "100px",
            selector: (row) => row?.amount || "-",
        },
        {
            name: "Date",
            width: "100px",
            selector: (row) => dateConverter(row.updatedAt),
        },
        {
            name: "Description",
            width: "150px",
            selector: (row) => row?.remarksDetailedExpenseDesc || "-",
        },
        // {
        //     name: "Merchant Name",
        //     width: "25%",
        //     selector: (row) => row.merchantName,
        // },
        {
            name: "Action",
            width: "100px",
            cell: (row) => {
                return (
                    <div className={styles.actions}>
                        <ViewIcon onClick={viewReceipt(row)} className={styles.actionIcon} />
                        {/* <EditIcon 
                            className={styles.actionIcon} 
                            onClick={() => openEditReceiptWalletPage(row)}
                        /> */}
                        <DeleteIcon 
                            className={styles.actionIcon} 
                            onClick={() => setDeleteModalDetails({
                                open: true,
                                id: row.id
                            })}
                        />
                    </div>
                )
            },
        },
    ];

    useEffect(() => {
        getData()
    }, [rowsPerPageValue, pageSelected]);

    useEffect(() => {
        getSelectOptions()
        dispatch(setUserDetailsPageName(user?.name || "Receipt Wallet"))
        return () => {
            dispatch(setUserDetailsPageName(""))
        }
    }, [])

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <AddReceiptWallet
                    modalOpen={receiptWalletModalOpen} 
                    setModalOpen={(flag) => dispatch(setSelectedReceiptWallet(flag))}
                    // handleUploadReceipt={handleUploadReceipt}
                    description={description}
                    setDescription={setDescription}
                    propOptions={propOptions}
                    unitOptions={unitOptions}
                    getData={getData}
                    key={receiptWalletModalOpen}
                />
            </Formik>
            <ConfirmDeleteModal 
                modalOpen={deleteModalDetails.open}
                setModalOpen={setDeleteModalOpen}
                handleDelete={handleDelete(deleteModalDetails.id)}
                deleteLabel="receipt"
            />
            <ViewAllReceiptDetails 
                modalOpen={viewModalDetails.open}
                setModalOpen={(flag) => setViewModalDetails({ open: flag , id: null})}
                data={viewModalDetails?.id || {}}
                heading={viewModalDetails?.id?.description || ""}
            />
            <div className={styles.pageHeader}>
                <div className={styles.headingRightSide}>
                    {/* <input
                        type="text"
                        className={styles.searchInput}
                        placeholder="Search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <button className={styles.filterButton}>
                        Filter
                    </button> */}
                    {/* {currentRole === "user" ?  */}
                    <Button
                        // onClick={() => dispatch(setReceiptWalletModalOpen(true))}
                        onClick={() => {navigate(`/receipt-wallet/new/${userId}`, {
                            state: {
                                unitOptions,
                                propOptions,
                                cjStores
                            }
                        })}}
                        disabled={isFetchingOptions}
                    >
                        Add New Receipt
                    </Button>
                    {/* : ""} */}
                </div>
            </div>
            <Table
                columns={column}
                data={receiptWallet}
                className={styles.candidatesTable}
                progressPending={isFetching}
                paginationProps={{
                    isPagination: true,
                    tableName: "Receipt",
                    currentPage: pageSelected,
                    totalCount: totalReceiptWallet,
                    rowsPerPageValue: rowsPerPageValue,
                    setRowsPerPageValue: setRowsPerPageValue,
                    setPageSelected: setPageSelected,
                }}
            />
        </>
    );
};

export default ReceiptWallet;
