import React from 'react'
import Modal from 'components/shared/modal/Modal';
import styles from "./SelectRoleModal.module.scss"
import { useNavigate } from 'react-router';

const SelectRoleModal = ({ modalOpen, setModalOpen }) => {

    const navigate = useNavigate()

    const handleClick = (role) => (e) => {
        setModalOpen(false)
        localStorage.setItem("currentRole", role)
        navigate("/")
    }

    return (  
        <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} heading={"Login as"} doNotClose={true}>
            <span className={styles.cardContainer}>
                <span className={styles.card} onClick={handleClick("user")}>User</span>
                <span className={styles.card} onClick={handleClick("approver")}>Approver</span>
            </span>
        </Modal>
    );
}
 
export default SelectRoleModal;