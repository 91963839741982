import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { ACCOUNT_DETAILS, API_APP_LOGGER, ERROR_APP_LOGGER, VIEW_LOGS_DATA } from "services/apiEndpoints";
import { privateGet, privatePut } from "services/privateRequest";
import { getDefaultDates } from "utils/utils";

const initialState = {
    accounts: [],
    accountsOnly: [],
    totalAccounts: 0,
    totalAccountsOnly: 0,
    selectedAccount: null,
    accountsModalOpen: false,
    isFetching: false,
    allAccounts: [],
    logsData: [],
    csvViewLogs: [],
    totalCsvViewLogs:  0,
    masterViewLogs: [],
    totalMasterViewLogs:  0,
    appLoggerData:[],
    appLoggerCount: 0,
    errorLoggerData:[],
    errorLoggerCount: 0,
    masterData: [],
    filterValues: {
        ...getDefaultDates(),
        ...JSON.parse(localStorage.getItem("filterValues")) || {},
    },
    csvFileFilterValues: {
        ...getDefaultDates(),
    },
    masterFileFilterValues: {
        ...getDefaultDates(),
    },
}

export const getAppLoggerData = createAsyncThunk("accounts/getAppLogger", async(params) => {
    const res = await privateGet(API_APP_LOGGER, { params })
    return {
        data: res?.data?.result || [],
        appLoggerCount : res?.data?.count || 0
    }
})

export const getErrorLoggerData = createAsyncThunk("accounts/getErrorLogger", async(params) => {
    const res = await privateGet(ERROR_APP_LOGGER, { params })
    return {
        data: res?.data?.result || [],
        count : res?.data?.count || 0
    }
})

//csv download
export const getCsvViewLogs = createAsyncThunk("accounts/getCsvViewLogs", async(params) => {
    const res = await privateGet(VIEW_LOGS_DATA, { params })

    return {
        data: res?.data?.data || [],
        totalCsvViewLogs: res?.data?.count || 0
    }
})

//master logs
export const getMasterViewLogs = createAsyncThunk("accounts/getMasterViewLogs", async(params) => {
    const res = await privateGet(VIEW_LOGS_DATA, { params })

    return {
        data: res?.data?.data || [],
        totalMasterViewLogs: res?.data?.count || 0
    }
})

// Accounts
export const getAccountsData = createAsyncThunk("accounts/getAccountsData", async(params) => {
    const res = await privateGet(ACCOUNT_DETAILS, { params })

    return {
        data: res?.data?.data || [],
        totalAccounts: res?.data?.pagination || 0
    }
})

export const getAccountsDataNew = createAsyncThunk("accounts/getAccountsData", async({page, limit, ...params}) => {
    const res = await privateGet(ACCOUNT_DETAILS + "/all", { params })

    return {
        data: res?.data?.data?.slice((page-1)*limit, page*limit) || [],
        totalAccounts: +res?.data?.count || 0
    }
})

export const getAccountsOnly = createAsyncThunk("accounts/getAccountsOnly", async(params) => {
    const res = await privateGet(
        ACCOUNT_DETAILS + "/all", 
        // CARD,
        { params }
    )
    return {
        accountsOnly: res?.data?.data || [],
        // totalAccountsOnly: res?.data?.pagination || 0,
        totalAccountsOnly: res?.data?.count || 0,
    }
})

export const getViewLogsData = createAsyncThunk("accounts/getViewLogsData", async(params) => {
    const res = await privateGet(VIEW_LOGS_DATA, {params})
    return {
        data: res?.data?.data[0] || [],
    }
})

export const getViewMasterFileLogsData = createAsyncThunk("accounts/getViewMasterFileLogsData", async(params) => {
    const res = await privateGet(VIEW_LOGS_DATA, {params})
    return {
        data: res?.data?.data[0] || [],
    }
})

export const deleteAccounts = createAsyncThunk("accounts/deleteAccounts", async(id) => {
    const res = await privatePut(ACCOUNT_DETAILS + "/delete", id)
    res && toast.success("Account Deactivated Successfully")
})

export const restoreAccounts = createAsyncThunk("accounts/restoreAccounts", async(id) => {
    const res = await privatePut(ACCOUNT_DETAILS + "/restore", id)
    res && toast.success("Account Activated Successfully")
})

const accountsSlice = createSlice({
    name: "accounts",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedAccount: (state, action) => {
            state.selectedAccount = action.payload
            state.accountsModalOpen = !!action.payload
        },
        setAccountsModalOpen: (state, action) => {
            state.accountsModalOpen = action.payload
        },
        setFilterValues: (state, action) => {
            state.filterValues = action.payload
        },
        clearAccounts: (state) => {
            state.accounts = initialState
        },
        setCsvFileFilterValues: (state, action) => {
            state.csvFileFilterValues = action.payload
        },
        setMasterFileFilterValues: (state, action) => {
            state.masterFileFilterValues = action.payload
        },
        setMasterFileData: (state, action) => {
            state.masterData = action.payload
        }, 
    },
    extraReducers: {
        [getAccountsData.pending]: (state) => {
            state.isFetching = true
        },
        [getAccountsData.fulfilled]: (state, action) => {
            state.accounts = action.payload.data
            state.totalAccounts = action.payload.totalAccounts
            state.isFetching = false
        },
        [getAccountsData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAccountsDataNew.pending]: (state) => {
            state.isFetching = true
        },
        [getAccountsDataNew.fulfilled]: (state, action) => {
            state.accounts = action.payload.data
            state.totalAccounts = action.payload.totalAccounts
            state.isFetching = false
        },
        [getAccountsDataNew.rejected]: (state) => {
            state.isFetching = false
        },
        [getAccountsOnly.pending]: (state) => {
            state.isFetching = true
        },
        [getAccountsOnly.fulfilled]: (state, action) => {
            state.accountsOnly = action.payload.accountsOnly
            state.totalAccountsOnly = action.payload.totalAccountsOnly
            state.isFetching = false
        },
        [getAccountsOnly.rejected]: (state) => {
            state.isFetching = false
        },
        [getViewLogsData.fulfilled]: (state, action) => {
            state.logsData = action.payload.data
        },
        [getCsvViewLogs.pending]: (state) => {
            state.isFetching = true
        },
        [getCsvViewLogs.fulfilled]: (state, action) => {
            state.csvViewLogs = action.payload.data
            state.totalCsvViewLogs = action.payload.totalCsvViewLogs
            state.isFetching = false
        },
        [getCsvViewLogs.rejected]: (state) => {
            state.isFetching = false
        },
        [getMasterViewLogs.pending]: (state) => {
            state.isFetching = true
        },
        [getMasterViewLogs.fulfilled]: (state, action) => {
            state.masterViewLogs = action.payload.data
            state.totalMasterViewLogs = action.payload.totalMasterViewLogs
            state.isFetching = false
        },
        [getMasterViewLogs.rejected]: (state) => {
            state.isFetching = false
        },
        [getAppLoggerData.pending]: (state) => {
            state.isFetching = true
        },
        [getAppLoggerData.fulfilled]: (state, action) => {
            state.appLoggerData = action.payload.data
            state.appLoggerCount = action.payload.appLoggerCount
            state.isFetching = false
        },
        [getAppLoggerData.rejected]: (state) => {
            state.isFetching = false
        },
        [getErrorLoggerData.pending]: (state) => {
            state.isFetching = true
        },
        [getErrorLoggerData.fulfilled]: (state, action) => {
            state.errorLoggerData = action.payload.data
            state.errorLoggerCount = action.payload.count
            state.isFetching = false
        },
        [getErrorLoggerData.rejected]: (state) => {
            state.isFetching = false
        },
        [getViewMasterFileLogsData.fulfilled]: (state, action) => {
            state.masterData = action.payload.data
        }
    }
})

export default accountsSlice.reducer
export const { 
    setIsFetching, 
    setSelectedAccount, 
    setAccountsModalOpen, 
    setFilterValues,
    setCsvFileFilterValues,
    setMasterFileFilterValues,
    clearAccounts,
    setMasterFileData
} = accountsSlice.actions