import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import classes from "App.module.css"
import "global.css"
import { useSelector } from "react-redux";
import { PATH } from "utils/constants";
import PublicRoute from "components/shared/routes/PublicRoute";
import PrivateRoute from "components/shared/routes/PrivateRoute";
import { checkEntityPermission, getCurrentUser } from "utils/utils";

import HomeLayout from "components/layouts/homelayout/HomeLayout";
import 'react-tooltip/dist/react-tooltip.css'

function App() {
  const { loggedInPermissions } = useSelector(state => state.rolesAndPermission)

  const defaultPath = getCurrentUser()?.roleName === "Developer" ? PATH.privateRoutes.APP_LOGGER.path : PATH.privateRoutes.HOME.path

  const roleWiseRouting = createBrowserRouter([
    //public routes
    ...Object.values(PATH.publicRoutes).map(route => ({
      path: route.path,
      element: <PublicRoute component={route.element}/>
    })),
    {
      //private routes
      path: "/",
      element: <PrivateRoute component={HomeLayout} />,
      children: [
          ...Object.values(PATH.privateRoutes)
          .map(child => ({
              path: child.path,
              element: child.element
          }))
          .filter(child => checkEntityPermission(child.path)),
          {
              path: "/",
              element: <Navigate to={defaultPath} />
          },
          {
              path: "*",
              element: <Navigate to={defaultPath} />
          },
      ]
    },
    {
      path: "*",
      element: <Navigate to="/" />
    },
  ])

  return (
    <div className={classes.app}>
      <RouterProvider router={roleWiseRouting} />
    </div>
  );
}

export default App;
