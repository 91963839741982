import React, { memo, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import Pagination from './Pagination'
import styles from './Table.module.scss'
import CircleSpinner from 'components/shared/loader/CircleSpinner'
import { combineClasses } from 'utils/utils'
import { useSelector } from 'react-redux'

const Table = (props) => {

    const { sidebarOpen } = useSelector(state => state.home)

    const handleMouseMove = (e) => {
        let ele = document.getElementsByClassName(styles.table)?.[0];
        let distance = ele?.offsetTop + ele?.offsetHeight - e?.clientY;
        distance < 12 && distance > 0 ? ele?.classList?.add(styles.moreWidth) : ele?.classList?.remove(styles.moreWidth);
    }

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove)
        }
    }, [])

    return (
        <>
            <DataTable 
                {...props}
                pagination={false}
                noDataComponent={<p className={styles.noData}>There are no records to display</p>}
                columns={props?.columns?.map(column => ({
                    ...column,
                    reorder: false
                }))}
                className={combineClasses(
                    styles.table, 
                    sidebarOpen ? styles.sidebarOpen : "", 
                    props.className || "",
                )}
                progressComponent={<CircleSpinner />}
                fixedHeader={true}
                fixedHeaderScrollHeight='calc(100vh - 225px)'
                responsive
            />
            {props?.paginationProps?.isPagination && <Pagination {...props?.paginationProps} isLastPage={props?.paginationProps.isLastPage} />}
        </>
    )
}

export default memo(Table)