import React, { useEffect, useMemo, useState } from "react";
import styles from "./UserDetails.module.scss"
import Button from "components/shared/button/Button";
// import Status from "../../components/Status";
// import { privateGet } from "services/privateRequest";
// import { CASHFLOW_LOGS, LOGS } from "../../services/endPoint";
// import Spinner from "../../components/Spinner";
import Table from "components/shared/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ViewIcon } from "assets/svg/view-icon.svg"
import { ReactComponent as EditIcon } from "assets/svg/edit-icon.svg"
import { ReactComponent as DeleteIcon } from "assets/svg/delete-icon.svg"
import { 
    deleteUserDetails, 
    getUserDetailsData, 
    setRejectedModalDetails, 
    setRejectedModalOpen, 
    setUserDetailsPageName,
} from "redux/slices/userDetailsSlice";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDeleteModal from "components/shared/confirmdeletemodal/ConfirmDeleteModal";
import { PATH } from "utils/constants";
import RejectionModal from "./RejectedModal";
import UserDetailsFilter from "./UserDetailsFilter";
// import AddUserDetails from './AddUserDetails';

const UserDetails = () => {
    // local state
    const [search, setSearch] = useState("");
    const [rowsPerPageValue, setRowsPerPageValue] = useState(10);
    const [count, setCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(1);
    const [deleteModalDetails, setDeleteModalDetails] = useState({
        open: false,
        id: null
    });
    const [currentUser, setCurrentUser] = useState();
    const [checkedArray, setCheckedArray] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);

    // redux state
    const { 
        isFetching, 
        userDetails, 
        rejectedModalOpen,
        totalUserDetails,
    } = useSelector(state => state.userDetails)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const pendingDetailsPage = params.parentPath === "home-pending"
    
    const openFilterModal = () => {
        setFilterOpen(true)
    }
    
    const userDetailsHeader = useMemo(() => {
        const temp = [
            {headerName: "Name", key: "name"},
            {headerName: "Email", key: "email"},
        ]
        switch("/" + params.parentPath) {
            case PATH.privateRoutes.HOME.path+"-approved":
                temp.push({headerName: "Approved", key: "approved"})
                break
            case PATH.privateRoutes.HOME.path+"-rejected":
                temp.push({headerName: "Rejected", key: "rejected"})
                break
            case PATH.privateRoutes.HOME.path+"-pending":
                temp.push({headerName: "Pending", key: "pending"})
                temp.push({headerName: "Rejected", key: "rejected"})
                break
            case PATH.privateRoutes.PENDING_BY_APPROVER.path:
                temp.push({headerName: "Pending", key: "pending"})
                temp.push({headerName: "Rejected", key: "rejected"})
                break
            default:
                break
        }
        return temp
    })

    const getData = () => {
        setCount(0)
        dispatch(getUserDetailsData({ page: pageSelected, limit: rowsPerPageValue }))
    }

    // const openEditUserDetailsPage = (userDetails) => {
    //     dispatch(setSelectedUserDetails(userDetails))
    // }

    const setDeleteModalOpen = (value) => {
        setDeleteModalDetails({
            ...deleteModalDetails,
            open: value
        })
    }

    const handleDelete = (id) => async() => {
        await dispatch(deleteUserDetails(id))
        setDeleteModalOpen(false)
    }

    const handleCheck = (e) => {
        const { checked, id } = e.target
        setCheckedArray((prev) => checked ? [...prev, +id] : prev.filter(x => x !== +id))
    }

    const handleHeaderCheck = (e) => {
        setCheckedArray(e.target.checked ? userDetails.map(x => x.id) : [])
    }

    const checkboxColumn = {
        name: (
            <input 
                type="checkbox"
                className={styles.checkbox}
                checked={checkedArray.length === userDetails.length}
                onChange={handleHeaderCheck}
            />
        ),
        width: "50px",
        selector: (row) => (
            <input 
                type="checkbox"
                className={styles.checkbox}
                id={row.id} 
                checked={checkedArray.includes(row.id)}
                onChange={handleCheck}
            />
        ),
    }

    const column = [
        {
            name: "Type",
            width: "75px",
            selector: (row) => (
                <div>
                    {row.type}
                </div>
            ),
        },
        {
            name: "Tran#",
            width: "75px",
            selector: (row) => row.id,
        },
        {
            name: "Vendor Name",
            width: "150px",
            selector: (row) => row.vendorName,
        },
        {
            name: "Date",
            width: "100px",
            selector: (row) => row.date,
        },
        {
            name: "Post Month",
            width: "100px",
            selector: (row) => row.postMonth,
        },
        {
            name: "Invoice#",
            width: "130px",
            selector: (row) => row.invoice,
        },
        {
            name: "Notes Merchant Name",
            width: "200px",
            selector: (row) => row.name,
        },
        {
            name: "Prop Code Entity",
            width: "150px",
            selector: (row) => row.propCodeEntity,
        },
        {
            name: "Amount",
            width: "100px",
            selector: (row) => row.amount,
        },
        // {
        //     name: "Action",
        //     width: "20%",
        //     cell: (row) => {
        //         // return "sample column text"
        //         return (
        //             <div className={styles.actions}>
        //                 {/* <ViewIcon className={styles.actionIcon} /> */}
        //                 <EditIcon 
        //                     className={styles.actionIcon} 
        //                     onClick={() => openEditUserDetailsPage(row)}
        //                 />
        //                 <DeleteIcon 
        //                     className={styles.actionIcon} 
        //                     onClick={() => setDeleteModalDetails({
        //                         open: true,
        //                         id: row.id
        //                     })}
        //                 />
        //             </div>
        //         )
        //     },
        // },
    ];

    useEffect(() => {
        getData()
    }, [rowsPerPageValue, pageSelected]);

    // useLayoutEffect(() => {
    //     currentUser && dispatch(setUserDetailsPageName(currentUser.name))
    // }, [currentUser])

    useEffect(() => {
        (async() => {
            // api call for user by id here
            const res = await new Promise(resolve => {
                setTimeout(() => {
                    resolve({
                        id: params.userId,
                        name: "Pranil Meherkar",
                        email: "pranil@gmail.com",
                        approved: 202,
                        pending: 114,
                        rejected: 558
                    })
                }, 1000)
            })
            setCurrentUser(res)
            dispatch(setUserDetailsPageName(res.name))
        })()

        return () => {
            dispatch(setUserDetailsPageName(""))
        }
    }, [])

    return (
        // <div className={styles.mainDiv}>
        <>
            {/* <AddUserDetails
                modalOpen={userDetailsModalOpen} 
                setModalOpen={(flag) => dispatch(setSelectedUserDetails(flag))}
                getData={getData}
            /> */}
            {pendingDetailsPage ? <RejectionModal 
                modalOpen={rejectedModalOpen} 
                setModalOpen={(flag) => dispatch(setRejectedModalDetails(flag))}
                getData={getData}
            />: ""}
            <ConfirmDeleteModal 
                modalOpen={deleteModalDetails.open}
                setModalOpen={setDeleteModalOpen}
                handleDelete={handleDelete(deleteModalDetails.id)}
                deleteLabel="userDetails"
            />

            <UserDetailsFilter modalOpen={filterOpen} setModalOpen={setFilterOpen} />
            <div className={styles.pageHeader}>
                <div className={styles.headingRightSide}>
                    {/* <input
                        type="text"
                        className={styles.searchInput}
                        placeholder="Search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    /> */}
                    {
                        pendingDetailsPage ?
                        <>
                            <Button className={styles.approveButton}>
                                Approve
                            </Button>
                            <Button 
                                className={styles.rejectButton}
                                onClick={() => dispatch(setRejectedModalOpen(true))}
                            >
                                Reject
                            </Button>
                        </>
                        :
                        <button className={styles.filterButton}
                        onClick={openFilterModal}
                        >
                            Filter
                        </button>
                    }
                    {/* <Button
                        onClick={() => dispatch(setUserDetailsModalOpen(true))}
                    >
                        Add New UserDetails
                    </Button> */}
                </div>
                <div className={styles.userDetailsHeader}>
                    {currentUser ? userDetailsHeader.map((data) => (
                        <span className={styles.container}>
                            <span className={styles.heading}>{data.headerName}</span>
                            <span className={styles.value}>{currentUser[data.key]}</span>
                        </span>
                    )): ""}
                </div>
            </div>
            <Table
                columns={pendingDetailsPage ? [checkboxColumn, ...column] : column}
                data={userDetails}
                className={styles.candidatesTable}
                progressPending={isFetching}
                // paginationProps={{
                //     isPagination: true,
                //     tableName: "UserDetails",
                //     currentPage: pageSelected,
                //     totalCount: 20,
                //     rowsPerPageValue: rowsPerPageValue,
                //     setRowsPerPageValue: setRowsPerPageValue,
                //     setPageSelected: setPageSelected,
                // }}
            />
        </>
    );
};

export default UserDetails;
