import React, { useState, useRef, useEffect, useMemo } from 'react'
import styles from './AddReceiptWallet.module.scss'
import Modal from 'components/shared/modal/Modal'

// icons
import { ReactComponent as UploadIcon } from "assets/svg/upload-icon.svg"
import { checkImageAndPdfFormat, combineClasses, getCurrentUser, requiredMessage } from 'utils/utils'
import FormikControl from 'components/formikcontrols/FormikControl'
import { ReactComponent as CrossIcon } from "assets/svg/x.svg"
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getReceiptWalletData } from 'redux/slices/receiptWalletSlice'
import { useFormikContext } from 'formik'
import { privatePost } from 'services/privateRequest'
import { UPLOAD_EXCEL } from 'services/apiEndpoints'
import { floatingPointNumberRegex } from 'utils/regex'


const AddReceiptWallet = ({ propOptions, unitOptions, setPaths, amount, ...props }) => {

    const [file, setFile] = useState([])
    const [receipt, setReceipt] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [descErr, setDescErr] = useState("");

    const { currentRole } = useSelector(state => state.rolesAndPermission)
    const { receiptWallet } = useSelector(state => state.receiptWallet)

    const formik = useFormikContext()

    const location = useLocation()
    const dispatch = useDispatch()
    const { userId } = useParams()

    let inputRef = useRef(null)

    const worrOptions = [
        { label: "-", value: null },
        { label: "WO", value: "WO" },
        { label: "RR", value: "RR" },
    ]

    const receiptOptions = useMemo(() => {
        return receiptWallet?.map(receipt => ({
            label: receipt?.description || receipt?.filename || "",
            value: receipt?.id || "",
        }))
    }, [receiptWallet])

    const handleFileChange = async(e, file) => {
        const selectedFile = e.target.files;

        if (selectedFile?.length || file) {
            if (
                Array(selectedFile.length)?.fill(null)
                ?.every((_, i) => checkImageAndPdfFormat(selectedFile?.[i]))
            ) {
                // if (selectedFile.size <= 2 * 1024 * 1024) {
                //     setFile(selectedFile);
                //     setErrorMessage('');
                // } else {
                //     setErrorMessage('File size exceeds 2 MB.');
                //     setFile(null);
                // }
                const temp = []
                for(let i = 0; i < selectedFile?.length; i++) {
                    const formData = new FormData()
                    formData.append("files", selectedFile[i])
                    // currentRole === "admin" && 
                    formData.append("userId", userId || getCurrentUser("userId"))
                    const res = await privatePost(UPLOAD_EXCEL + "?isCcReceipt=true", formData)
                    if(res) {
                        const path = res?.data?.data?.[0]?.path
                        temp.push(path?.split("/").slice(1).join("/"))
                    }
                }
                formik?.setFieldValue?.("path", [...formik.values?.path, ...temp]) || setPaths(prev => [...prev, ...temp])
                setFile(prev => file || [...prev, ...selectedFile]);
                setErrorMessage('');
            } else {
                setErrorMessage('Please select a JPG, PNG or PDF file.');
                // setFile([]);
            }
        } else {
            // setFile([]);
            setErrorMessage('');
        }
    };


    const removeFile = (index) => () => {
        setFile(prev => prev.filter((_, i) => i !== index));
        setPaths?.(prev => prev.filter((_, i) => i !== index))
    };
    const addFile = () => {
        inputRef.current.click()
    }

    const handleSubmit = () => {
        if(!formik) {
            if(currentRole === "admin" && !file.length) {
                setErrorMessage(requiredMessage("Receipt"))
                return
            } else if (currentRole === "user" && !file.length && !receipt) {
                setErrorMessage(requiredMessage("Receipt"))
                return
            }
        }
        props.handleUploadReceipt?.(receipt?.value || file) || formik?.handleSubmit()
    
        // props.setModalOpen(false)
        // inputRef.current.value = ""
        // setErrorMessage("")
        // setDescErr("")
        // setReceipt(null)
        // setDescription("")
    }

    const closeModal = () => {
        setFile([])
        setErrorMessage("")
        setDescErr("")
        setReceipt(null)
        props.setModalOpen(false)
        formik?.resetForm?.()
        setPaths?.([])
    }

    const showReceiptDropdown = currentRole === "user" && location?.pathname?.includes("home")

    useEffect(() => {
        setDescErr("")
        showReceiptDropdown && props?.modalOpen && dispatch(getReceiptWalletData({ userId: getCurrentUser("userId"), params: { amount } }))
    }, [props?.modalOpen])

    return (
        <Modal
            heading={"Upload Receipt"}
            className={styles.mainModalClass}
            {...props}
            setModalOpen={closeModal}
        >
            <div 
                className={styles.fileUploadDiv}
                onDrop={(e) => {
                    e.preventDefault();
                    handleFileChange(e, e.dataTransfer.files);
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                }}
            >   
                <label className={styles.label} htmlFor={"description"}>
                        {"Receipt"} <span className={styles.requiredMark}>*</span>
                    </label>
                <div className={styles.uploadInputContainer}>

                {/* <img src={fileUploadIcon} alt="File Upload Logo" className={styles.fileUploadLogoImage} /> */}
                <UploadIcon className={styles.fileUploadLogoImage}/>
                {file?.length ? 
                <div className={styles.fileNamesDiv}>
                    {
                        Array(file?.length)?.fill(null)?.map((_, i) => (
                            <p className={styles.fileName}>
                                {file?.[i]?.name || ""} 
                                <CrossIcon className={styles.crossIcon} onClick={removeFile(i)}/>
                            </p>
                        ))
                    }
                </div>
                : <p className={styles.dragDropText}>Drag & Drop your files here</p>}
                {(formik?.errors?.path || !file?.[0]) && <span className={combineClasses(styles.fileUploadMessage, "validation-error-text")}>
                    {formik?.errors?.path || errorMessage}
                </span>}
                {!errorMessage && (
                    <p className={styles.infoText}>
                        {false ? `Size: ${(file?.size / 1024 / 1024).toFixed(2)} MB` : 'Files supported .jpg, .png & .pdf'}
                    </p>
                )}
                <span className={false && styles.fileUploadMessage}>{false ? 'File Attached' : 'or'}</span>
                <div className={styles.fieldDiv}>
                    <div className={styles.fileDiv}>
                        <input 
                            type="file"
                            id="fileInput"
                            className={`${styles.customfileinput} ${file.length && styles.hideInput}`}
                            onChange={(e) => handleFileChange(e)}
                            accept=".jpg,.png,.pdf"
                            ref={inputRef}
                            multiple
                        />
                        <button onClick={addFile} className={`${styles.browseFileBtn} ${false && styles.fileUploadField}`}>{false ? "Remove file" : 'Browse file'}</button>
                    </div>
                    <p className={styles.fileLimitText}>{true ? '' : 'Maximum size : 2MB'}</p>
                </div>
                </div>
                {/* <div className={styles.inputControl}>
                    <label className={styles.label} htmlFor={"description"}>
                        {"Description"} 
                        <span className={styles.requiredMark}>*</span>
                    </label>
                    <input
                        id={"description"}
                        type={"text"}
                        className={styles.inputField}
                        placeholder={"Enter Description"}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                            setDescErr("")
                        }}
                    />
                    <span className={styles.descErr}>{descErr}</span>
                </div> */}
                {
                    formik && 
                    <>
                        <FormikControl
                            name="remarksDetailedExpenseDesc"
                            control="input"
                            label="Description"
                            placeholder="Enter Description"
                            isRequired={true}
                        />
                        <FormikControl
                            name="propCodeId"
                            control="select"
                            label="Prop Code"
                            placeholder="Select Prop Code"
                            formik={formik}
                            options={propOptions}
                            isRequired={true}
                        />
                        <FormikControl
                            name="unitCodeId"
                            control="select"
                            label="Unit Code"
                            placeholder="Select Unit Code"
                            formik={formik}
                            options={unitOptions?.[formik?.values?.propCodeId?.value] || []}
                            isRequired={true}
                            isMulti={true}
                            isDisabled={!formik?.values?.propCodeId}
                        />
                        <FormikControl
                            name="worr"
                            control="select"
                            label="WO/RR"
                            placeholder="Select WO/RR"
                            formik={formik}
                            options={worrOptions}
                            // isRequired={true}
                        />
                        <FormikControl
                            name="amount"
                            control="input"
                            label="Amount"
                            placeholder="Enter Amount"
                            isRequired={true}
                            onKeyDown={(e) => {
                                !(floatingPointNumberRegex.test(e.key) || e.key === "Backspace") && e.preventDefault()
                            }}
                        />
                    </>
                }
                <div>
                    <p className={combineClasses(styles.orText, !showReceiptDropdown ? styles.hide : "")}>
                        OR
                    </p>
                    {showReceiptDropdown && <FormikControl
                        value={receipt}
                        onChange={(value) => {setReceipt(value)}}
                        name="receipt"
                        control="select"
                        label="Receipt"
                        placeholder="Select Receipt"
                        options={receiptOptions}
                        // onChange={(value) => {
                        //     props.setModalOpen(false)
                        //     setReceiptPath(value.path)
                        // }}
                    />}
                </div>
                <div className={styles.buttonsContainer}>
                    <button 
                        className={styles.uploadFileBtn} 
                        onClick={(e) => {
                            // if(!receipt && (
                            //     !description || 
                            //     !file.length)) {
                            //     !description && setDescErr(requiredMessage("Description"))
                            //     !file.length && setErrorMessage("Receipt is required!")
                            // } else if(receipt || file.length) {
                            //     handleSubmit(e)
                            // }
                            handleSubmit(e)
                        }}
                    >
                        Upload
                    </button>
                    <button className={styles.cancelButton} onClick={closeModal}>Cancel</button>
                </div>
            </div>
        </Modal>
    )
}

export default AddReceiptWallet