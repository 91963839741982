import React, { useEffect, useRef, useState } from "react";
import styles from "./OtpVerification.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "components/shared/toast/toast";
import { emailRegex, numberRegex } from "utils/regex";
import { LOGIN } from "services/apiEndpoints";
import publicRequest from "services/publicRequest";
import LoginLayout from "components/layouts/loginlayout/LoginLayout";
import * as Yup from "yup"
import { combineClasses, onlyNumbersInput, requiredMessage } from "utils/utils";
import Button from 'components/shared/button/Button';
import { PATH } from "utils/constants";
import { ReactComponent as BackIcon } from "assets/svg/arrow-left.svg"

const otpLength = 6

const OtpVerification = () => {
    const [otp, setOtp] = useState(Array.from({ length: otpLength }));

    const navigate = useNavigate();
    const location = useLocation()

    const refArray = Array.from({ length: otpLength }, () => React.createRef(null))

    const handleSubmit = (e) => {
        e.preventDefault()

        const otpString = otp.join("")
        if (otpString.length < 6) return

        navigate(PATH.publicRoutes.VERIFY_PASSWORD.path, { state: { email: location?.state?.email } })
    }

    const handleInputChange = (e) => {
        const { value, name } = e.target
        if (value.length <= 1) {
            otp[name] = value
            setOtp([...otp])
            if (value && name < otpLength - 1) {
                refArray[+name + 1].current.focus()
            }
            // else if(!value && !otp[name] && name > 0) {
            //     refArray[+name-1].current.focus()
            // }
        }
    }

    useEffect(() => {
        refArray[0].current.focus()
    }, [])

    return (
        <LoginLayout>
            <div className={styles.mainDiv}>
                <BackIcon
                    onClick={() => navigate(-1)}
                    className={styles.backIcon}
                />
                <p className={styles.heading}>Enter OTP</p>
                <p className={styles.subHeading}>OTP has been sent to</p>
                <p className={styles.selectedEmail}>
                    {location?.state?.email || "sample@gmail.com"}
                    <span className="link-text">Change</span>
                </p>
                <form className={styles.form} autoComplete="off" onSubmit={handleSubmit}>
                    <div className={styles.inputsContainer}>
                        {Array.from({ length: otpLength }).map((_, i) => {
                            return (
                                <input
                                    key={i}
                                    name={i}
                                    ref={refArray[i]}
                                    type="number"
                                    value={otp[i]}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => {
                                        onlyNumbersInput(e)
                                        if (e.key === "Backspace" && !otp[i] && i > 0) {
                                            refArray[+i - 1].current.focus()
                                        }
                                    }}
                                    onPaste={(e) => {
                                        const pastedText = e.clipboardData.getData('Text').slice(0, otpLength)
                                        setOtp(pastedText.split(""));
                                        refArray[pastedText.length - 1].current.focus()
                                    }}
                                />
                            )
                        })}
                    </div>
                    <Button
                        type="submit"
                        className={styles.button}
                    >
                        Submit
                    </Button>
                    <p className={styles.bottomText}>
                        Didn't get OTP? <span
                            className="link-text"
                        >
                            Resend
                        </span>
                    </p>
                </form>
            </div>
        </LoginLayout>
    );
};

export default OtpVerification;