import { lazy } from "react";
import { ReactComponent as HomeIcon } from "assets/svg/home.svg"
import { ReactComponent as UsersIcon } from "assets/svg/User.svg"
import { ReactComponent as MastersIcon } from "assets/svg/master.svg"
import { ReactComponent as PendingByApproverIcon } from "assets/svg/pending-by-approver.svg"
import { ReactComponent as ReceiptWalletIcon } from "assets/svg/receiptwallet.svg"
import { ReactComponent as RolesIcon } from "assets/svg/roles.svg"
import { ReactComponent as PermissionsIcon } from "assets/svg/permissions-icon.svg"
import { ReactComponent as ModuleIcon } from "assets/svg/module-icon.svg"
import { ReactComponent as AccountsIcon } from "assets/svg/accounts-icon.svg"
import { ReactComponent as EmailConfigurationIcon } from "assets/svg/emailconfiguration.svg"
import { ReactComponent as ApproverIcon } from "assets/svg/approver.svg"
import { ReactComponent as UserAccountIcon } from "assets/svg/useraccount.svg"
import { ReactComponent as ReportIcon } from "assets/svg/report.svg"
import { ReactComponent as PendingIcon } from "assets/svg/pending.svg"

// component imports
import Login from "components/pages/login/Login";
import Register from "components/pages/register/Register";
import UserDetails from "components/pages/userdetails/UserDetails";
import ReceiptWallet from "components/pages/receiptwallet/ReceiptWallet";
import ForgotPassword from "components/pages/otpverification/ForgotPassword";
import OtpVerification from "components/pages/otpverification/OtpVerification";
import VerifyPassword from "components/pages/otpverification/VerifyPassword";
import ViewLogs from "components/pages/viewlogs/ViewLogs";
import MasterViewLogs from "components/pages/viewlogs/MasterViewLogs";

// lazy imports
const UserAccount = lazy(() => import("components/pages/useraccount/UserAccount"));
const AddReceiptWalletNew = lazy(() => import("components/pages/receiptwallet/AddReceiptWalletNew"));
const Home = lazy(() => import("components/pages/home/Home"))
const SendMasterFile = lazy(() => import("components/pages/home/SendMasterFile"))
const Users = lazy(() => import("components/pages/users/Users"))
const Roles = lazy(() => import("components/pages/rolesandpermission/Roles"))
const AddRole = lazy(() => import("components/pages/rolesandpermission/AddRole"))
const Master = lazy(() => import("components/pages/master/Master"))
const Permissions = lazy(() => import("components/pages/rolesandpermission/Permissions"))
const Entity = lazy(() => import('components/pages/entity/Entity'));
const PendingByApprover = lazy(() => import('components/pages/pendingbyapprover/PendingByApprover'));
const PropWiseUnit = lazy(() => import('components/pages/master/PropWiseUnit'));
// const PendingByAdmin = lazy(() => import('components/pages/pendingbyadmin/PendingByAdmin'));
const ViewPermission = lazy(() => import('components/pages/rolesandpermission/ViewPermission'));
const Approver = lazy(() => import("components/pages/approver/Approver"));
const Accounts = lazy(() => import("components/pages/accounts/Accounts"));
const PendingByUser = lazy(() => import("components/pages/pendingbyuser/PendingByUser"));
const AccountsMaster = lazy(() => import("components/pages/accounts/AccountsMaster"));
const EmailConfiguration = lazy(() => import("components/pages/emailconfiguration/EmailConfiguration"));
const Report = lazy(() => import("components/pages/report/Report"));
const AppLogger = lazy(() => import("components/pages/applogger/AppLogger"))
const ErrorLogger = lazy(() =>  import("components/pages/applogger/ErrorLogger"))
const EditEmailConfiguration = lazy(() => import("components/pages/emailconfiguration/EditEmailConfiguration"));

// roles
export const roles = ["admin", "approver"]

// all paths
export const PATH = {
  // DEFAULT: {
  //     path: "/",
  //     pageName: "Home",
  // },
  publicRoutes: {
    LOGIN: {
      path: '/login',
      element: Login
    },
    REGISTER: {
      path: "/register",
      element: Register
    },
    FORGOT_PASSWORD: {
      path: "/forgot-password",
      element: ForgotPassword
    },
    OTP_VERIFICATION: {
      path: "/otp-verification",
      element: OtpVerification
    },
    VERIFY_PASSWORD: {
      path: "/verify-password/:id",
      element: VerifyPassword
    },
  },
  privateRoutes: {
    HOME: {
      path: "/home",
      element: <Accounts key={"/home"}/>,
      pageName: "Home",
      sidebar: {
        show: true,
        icon: HomeIcon,
      }
    },
    CC_DETAILS : {
      path: "/home/cc-details/:accountId",
      element: <Home />,
      // pageName: "CC Details",
      showBackIcon: true,
    },
    USER_DETAILS : {
      path: "/:parentPath/user-details/:userId",
      element: <UserDetails />,
      pageName: false
    },
    SEND_MASTER_FILE: {
      path: "/home/send-master-file",
      element: <SendMasterFile />,
      pageName: "Home",
    },
    CSV_VIEW_LOGS: {
      path: "/home/csv-viewlogs",
      element: <ViewLogs/>,
      pageName: "CSV View Logs", 
      showBackIcon: true,
    },
    MASTER_VIEW_LOGS: {
      path: "/home/master-viewlogs",
      element: <MasterViewLogs/>,
      pageName: "Master View Logs", 
      showBackIcon: true,
    },
    USERS: {
      path: "/users",
      element: <Users />,
      pageName: "Users",
      sidebar: {
        show: true,
        icon: UsersIcon,
      },
    },
    MASTER: {
      path: "/master",
      element: <Master />,
      pageName: "Master",
      sidebar: {
        show: true,
        icon: MastersIcon,
      },
    },
    PROP_WISE_UNIT: {
      path: "/master/unit/:propId",
      element: <PropWiseUnit />,
      pageName: "Unit Code",
      showBackIcon: true,
    },
    ENTITY_WISE_STORE: {
      path: "/master/store/:propId",
      element: <PropWiseUnit />,
      pageName: "Stores",
      showBackIcon: true,
    },
    PENDING_BY_APPROVER: {
      path: "/pending-by-approver",
      element: <Accounts key={"/pending-by-approver"}/>,
      pageName: "Pending By Approver",
      sidebar: {
        show: true,
        icon: PendingIcon,
      }  
    },
    PENDING_BY_APPROVER_CC: {
      path: "/pending-by-approver/cc-details/:accountId",
      element: <PendingByApprover />,
      showBackIcon: true 
    },
    PENDING_BY_USER: {
      path: "/pending-by-user",
      element: <Accounts key={"/pending-by-user"}/>,
      pageName: "Pending By User",
      sidebar: {
        show: true,
        icon: PendingIcon,
      }  
    },
    PENDING_BY_USER_CC: {
      path: "/pending-by-user/cc-details/:accountId",
      element: <PendingByUser />,
      showBackIcon: true 
    },
    EMAIL_CONFIGURATION: {
      path: "/email-configuration",
      element: <EmailConfiguration/>,
      pageName: "Email Configuration",
      sidebar: {
        show: true,
        icon: EmailConfigurationIcon,
      }  
    },
    EDIT_EMAIL_CONFIGURATION: {
      path: "/email-configuration/edit",
      element: <EditEmailConfiguration/>,
      pageName: "Edit Email Configuration", 
    },
    // PENDING_BY_ADMIN: {
    //   path: "/pending-by-admin",
    //   element: <PendingByAdmin />,
    //   pageName: "Pending By Admin",
    //   sidebar: {
    //     show: true,
    //     icon: PendingByApproverIcon,
    //   }  
    // },
    ACCOUNTS_MASTER: {
      path: "/accounts",
      element: <AccountsMaster />,
      pageName: "Accounts",
      sidebar: {
        show: true,
        icon: AccountsIcon,
      },
    },
    APPROVER: {
      path: "/approver",
      element: <Approver />,
      pageName: "Approver",
      sidebar: {
        show: true,
        icon: ApproverIcon,
      },
    },
    USER_ACCOUNT: {
      path: "/user-account",
      element: <UserAccount />,
      pageName: "User Account",
      sidebar: {
        show: true,
        icon: UserAccountIcon,
      },
    },
    ROLES: {
      path: "/roles",
      element: <Roles />,
      pageName: "Roles",
      sidebar: {
        show: true,
        icon: RolesIcon,
      },
    },
    ADD_ROLE: {
      path: "/roles/add",
      element: <AddRole />,
      pageName: "Add Role"
    },
    EDIT_ROLES: {
      path: "/roles/:roleId",
      element: <AddRole />,
      pageName: "Edit Role",
    },
    VIEW_PERMISSION: {
      path: "/roles/permission/:roleId",
      element: <ViewPermission />,
      pageName: "Permissions"
    },
    PERMISSIONS: {
      path: "/permissions",
      element: <Permissions />,
      pageName: "Permissions",
      sidebar: {
        show: false,
        icon: PermissionsIcon,
      },
    },
    ENTITY: {
      path: "/entity",
      element: <Entity />,
      pageName: "Modules",
      sidebar: {
        show: false,
        icon: ModuleIcon,
      },
    },
    RECIEPT_WALLET: {
      path: "/receipt-wallet",
      element: <Users />,
      pageName: "Receipt Wallet",
      sidebar: {
        show: true,
        icon: ReceiptWalletIcon,
      },
    },
    ADD_RECIEPT_WALLET: {
      path: "/receipt-wallet/new/:userId",
      element: <AddReceiptWalletNew />,
      pageName: "Add New Receipt",
      showBackIcon: true,
    },
    RECIEPT_WALLET_RECEIPTS: {
      path: "/receipt-wallet/:userId",
      element: <ReceiptWallet />,
      // pageName: "Receipt Wallet",
      showBackIcon: true,
    },
    REPORT : {
      path: "/report",
      element: <Report />,
      pageName: "Report",
      sidebar: {
        show: true,
        icon: ReportIcon,
      },
    },
    APP_LOGGER : {
      path: "/app-logger",
      element: <AppLogger />,
      pageName: "App Logger",
      sidebar: {
        show: true,
        icon: ModuleIcon,
      },
    },
    ERROR_LOGGER : {
      path: "/error-logger",
      element: <ErrorLogger />,
      pageName: "Error Logger",
      sidebar: {
        show: true,
        icon: ModuleIcon,
      },
    },
  },
}

export const entityNames = {
  [PATH.privateRoutes.ROLES.path] : "role", 
  [PATH.privateRoutes.ENTITY.path] : "entity", 
  [PATH.privateRoutes.PERMISSIONS.path] : "permission",
  [PATH.privateRoutes.MASTER.path] : ["glCode", "unit", "propCode", "cc-description"], //[4, 6, 9]
  [PATH.privateRoutes.USERS.path] : "user",
  [PATH.privateRoutes.HOME.path] : "ccDetails",
  [PATH.privateRoutes.PENDING_BY_APPROVER.path]: "role",
  [PATH.privateRoutes.PENDING_BY_USER.path]: "ccDetails",
  [PATH.privateRoutes.APPROVER.path]: "user-approver",
  [PATH.privateRoutes.RECIEPT_WALLET.path]: "upload",
  [PATH.privateRoutes.ACCOUNTS_MASTER.path]: "user",
  [PATH.privateRoutes.USER_ACCOUNT.path]: "user-approver",
  [PATH.privateRoutes.EMAIL_CONFIGURATION.path]: "role",
  [PATH.privateRoutes.REPORT.path]: "reports",
  [PATH.privateRoutes.APP_LOGGER.path]: "api-logger",
  [PATH.privateRoutes.ERROR_LOGGER.path]: "api-logger",
}

//this is temporary
export const tempPagesArray = [
  // ...Object.values(PATH.privateRoutes).map(x => x.path),
  PATH.privateRoutes.USER_DETAILS.path,
]

// pagination
export const PAGINATION = {
  ROWS_PER_PAGE: [10, 25, 50],
  DEFAULT_ROWS_PER_PAGE: 25,
  DEFAULT_CURRENT_PAGE: 1,
  NO_BREAK_PAGE: 4,
};


// constants
export const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
}

export const statusColors = {
  pending: {
    background: "#FFEBF9",
    color: "#DE0097"
  },
  success: {
    background: "#D2FBF0",
    color: "#0AAB83"
  },
}

export const homeTabNames = ["Approved", "Pending", "Rejected"]
export const EmailConfigurationTabs = ["Users", "Approver", "Admin"]

export const masterTabNames = ["Property Code", false, "Unit Code", "GL Code", "Entity", "Store"]

export const reportTabNames = ["Approver Report", "User Report", "Rejected Report"]

export const methodOptions = [
  { label: "GET", value: "GET" },
  { label: "POST", value: "POST" },
  { label: "PUT", value: "PUT" },
  { label: "DELETE", value: "DELETE" },
  { label: "PATCH", value: "PATCH" },
]

export const passwordInstructions = [
  "contain atleast 1 lowercase character",
  "contain atleast 1 uppercase character",
  "contail atleast 1 special character",
  "be at least 8 characters long",
  "be maximum 20 characters long"
]

export const rejectionThreshold = 3

export const dontShowInstructionsKey = "dontShowInstructions"