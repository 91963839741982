import Modal from 'components/shared/modal/Modal';
import React, { useEffect, useState } from 'react'
import { RECEIPT_WALLET, RECEIPT_WALLET_NEW } from 'services/apiEndpoints';
import { privateGet } from 'services/privateRequest';
import styles from "./ViewReceipt.module.scss"
import Button from 'components/shared/button/Button';
import { ReactComponent as DownloadIcon } from "assets/svg/download.svg"
import { download } from 'utils/utils';
import CircleSpinner from 'components/shared/loader/CircleSpinner';

const ViewReceipt = ({ modalOpen, setModalOpen, heading: title, id, path }) => {

    const [receiptData, setReceiptData] = useState(null);
    const [image, setImage] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    const imageUrl = []
    // process.env.REACT_APP_API_BASE_URL + 
    // "/" + (path || receiptData?.path) || ""

    for(let pathLink of (path || receiptData?.path || [])) {
        imageUrl.push(`${process.env.REACT_APP_API_BASE_URL}/${pathLink?.path || pathLink}`)
    }

    const getReceiptData = async () => {
        if(!id) return
        setIsFetching(true)
        const res = await privateGet(`${RECEIPT_WALLET_NEW}/${id}`)
        setReceiptData(res?.data?.data)
    }

    const getImage = async() => {
        if(!imageUrl?.length) return
        setIsFetching(true)
        for(let url of imageUrl) {
            fetch(url, {
                method: 'GET',
            })
            .then((response) => response.blob())
            .then((blob) => {
                const imageUrl = URL.createObjectURL(blob);
                setImage(prev => [...prev, imageUrl])
            })
            .catch(error => {
                console.log(error?.message)
            })
        }
        setIsFetching(false)
    }

    const getScreenSize = () => {
        setMobileView(window.innerWidth < 900)
    }

    const heading = (
    <div className={styles.heading}>
    <span>
        {receiptData?.remarksDetailedExpenseDesc || title || ""}
    </span>
    <span className={styles.buttonContainer}>
        <Button 
            onClick={(e) => {
                e.stopPropagation()
                for(let url of imageUrl) {
                    window.open((mobileView && url.endsWith(".pdf") ? "https://docs.google.com/viewerng/viewer?url=" : "") + url, "_blank")
                }
            }}
        >
            Open in New Tab
        </Button>
        <Button 
            onClick={() => {
                for(let url of imageUrl) {
                    download(url)
                }
            }}
        >
            <DownloadIcon style={{margin: 0, height: "16px", padding: 0}} />
        </Button>
    </span>
    </div>)

    useEffect(() => {
        modalOpen && getScreenSize()
        !path && modalOpen && getReceiptData()
        return () => {
            setImage([])
            setReceiptData(null)
        }
    }, [modalOpen])

    useEffect(() => {
        if(modalOpen && (path || receiptData?.path)) {
            !image.length && getImage()
        }
    }, [modalOpen, receiptData])

    return <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} heading={heading}>
        <div>
            {false && receiptData?.description && 
            <h5 className={styles.description}>Description: <span>{receiptData?.description}</span></h5>}
            <div className={styles.spinnerDiv}>
            {
                isFetching ?
                <CircleSpinner />:
                <div className={styles.multipleReceiptsContainer}>
                    {
                        imageUrl?.map((url, i) => {

                            return url.endsWith(".pdf") ?
                                <iframe
                                    src={
                                        mobileView ? 
                                        `https://docs.google.com/gview?embedded=true&url=${imageUrl?.[i]}` :
                                        image?.[i]
                                    }
                                    frameborder="0"
                                    className={styles.view}
                                ></iframe>
                                :
                                <img
                                    className={styles.view}
                                    src={image?.[i] || ""}
                                    alt="Receipt"
                                />
                        })
                    }
                </div>
            }
            </div>
        </div>
    </Modal>
}

export default ViewReceipt;
