import Modal from 'components/shared/modal/Modal';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react'
import { requiredMessage } from 'utils/utils';
import * as Yup from "yup"
import FormikControl from 'components/formikcontrols/FormikControl';
import styles from "./UserDetailsFilter.module.scss"
import Button from 'components/shared/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { privatePost, privatePut } from 'services/privateRequest';
import toast from 'components/shared/toast/toast';

const UserDetailsFilter = ({ modalOpen, setModalOpen, getData }) => {
    const dispatch = useDispatch()
    const { 
        isFetching, 
        userDetails, 
        rejectedModalOpen,
        totalUserDetails,
    } = useSelector(state => state.userDetails)
    const closeModal = () => setModalOpen(false)

    const merchantOption = useMemo(() => {
        return userDetails.length && userDetails.map(item => ({
            label: item.name,
            value: item.id,
        })) || []
    }, [userDetails])

    const amountOption = useMemo(() => {
        return userDetails.length && userDetails.map(amount => ({
            label: amount.amount,
            value: amount.amount,
        })) || []
    }, [userDetails])

    const initialValues = {
        
    }

    const validationSchema = Yup.object({
       
    })

    const handleSubmit = async(values, { resetForm }) => {
        // const res = selectedMaster ? await privatePut(HOME, selectedMaster.id, values) : await privatePost(HOME, values)
        // if (res) {
        //     closeModal()
        //     resetForm()
        //     getData()
        // }
    }
    return (  
        <Modal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            heading={`Filter`}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => {
                    return (
                <Form className={styles.form}>
                       <FormikControl
                                name="merchant"
                                control="select"
                                label="Filter By Merchant Name"
                                options={merchantOption}
                                formik={formik}
                                placeholder="Select"
                                isRequired={true}
                            />
                     <FormikControl
                                name="amount"
                                control="select"
                                label="Filter By Amount"
                                options={amountOption}
                                formik={formik}
                                placeholder="Select"
                                isRequired={true}
                            />
                    <span className={styles.buttonsContainer}>
                        <Button
                            className={styles.cancelButton}
                            onClick={closeModal}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">
                            {`Submit`}
                        </Button>
                    </span>
                </Form>
                   )
                }}
            </Formik>
        </Modal>
    );
}
 
export default UserDetailsFilter;