import styles from "./ViewLogs.module.scss"
import Table from 'components/shared/table/Table'
import { ReactComponent as DownloadIcon } from "assets/svg/download.svg"
import React, { useEffect, useMemo, useState } from 'react'
import { dateConverter, download, timeConverter } from "utils/utils"
import { setMasterFileFilterValues} from "redux/slices/accountsSlice";
import { PAGINATION } from "utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { getMasterViewLogs } from "redux/slices/accountsSlice"
import HomeFilter from "../home/HomeFilter"

const MasterViewLogs = () => {

    const [search, setSearch] = useState("");
    const [masterFileFilter, setMasterFileFilter] = useState(false);
    const [pageSelected, setPageSelected] = useState(1);
    const [rowsPerPageValue, setRowsPerPageValue] = useState(PAGINATION.DEFAULT_ROWS_PER_PAGE);
    const dispatch = useDispatch();

    const {
        isFetching,
        totalMasterViewLogs,
        masterViewLogs,
        masterFileFilterValues
    } = useSelector(state => state.accounts)

    const openFilterModal = () => {
        setMasterFileFilter(true)
    }

    const getData = (page) => {
        const params = { 
            isMasterFile: true, 
            page: page || pageSelected,
            limit: rowsPerPageValue, 
            search,
            ...masterFileFilterValues
         }
        dispatch(getMasterViewLogs(params))
    }

    const columns = useMemo(() => [
        {
            name: "User",
            selector: row => row?.masterFile?.user?.firstName + ' ' + (row?.masterFile?.user?.lastName ? row?.masterFile?.user?.lastName : ''),
            width: "200px",
        },
        {
            name: "Date",
            selector: row => dateConverter(row?.masterFile?.createdAt),
            width: "200px",
        },
        {
            name: "Created At",
            selector: row => timeConverter(row?.masterFile?.createdAt),
            width: "200px",
        },
        {
            name: "Status",
            selector: row =>(row?.masterFile?.description==="masterFile" ? "Pass" : "Fail") ,
            width: "200px",
        },
        {
            name: "Download",
            selector: row => <DownloadIcon
            onClick={() => {
                const filePath = row?.masterFile?.path.replace('public', '');
                download(`${process.env.REACT_APP_API_BASE_URL}/${filePath}`)
            }} 
            className={styles.actionIcon} 
            stroke="#666"
        />,
            width: "300px",
        }
    ], []);

    useEffect(() => {
        !search && getData()
        
    }, [search, rowsPerPageValue, pageSelected, masterFileFilterValues]);

    useEffect(() => {
        const x = setTimeout(() => {
            search && getData(1)
        }, 500)
        return () => {
            clearTimeout(x)
        }
    }, [search, pageSelected])

  return (
    <>
    <HomeFilter
        modalOpen={masterFileFilter} 
        setModalOpen={setMasterFileFilter} 
        filterValues={masterFileFilterValues}
        setFilterValues={setMasterFileFilterValues}
    />
        <div className={styles.pageHeader}>
                <div className={styles.headingRightSide}>
                    <input
                        type="text"
                        className={styles.searchInput}
                        placeholder="Search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <button className={styles.filterButton} onClick={openFilterModal}>
                        Filter
                    </button>
                </div>
        </div>
        <Table
                columns={columns}
                data={masterViewLogs}
                // // className={styles.candidatesTable}
                progressPending={isFetching}
                paginationProps={{
                    isPagination: true,
                    tableName: "Accounts",
                    currentPage: pageSelected,
                    totalCount: totalMasterViewLogs,
                    // totalCount: 
                    // currentRole === "admin" && location.pathname === "/home" ? 33 : 
                    // totalAccounts,
                    rowsPerPageValue: rowsPerPageValue,
                    setRowsPerPageValue: setRowsPerPageValue,
                    setPageSelected: setPageSelected,
                }}
            />
    </>
    
  )
}

export default MasterViewLogs