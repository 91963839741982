import React from 'react'
import Modal from 'components/shared/modal/Modal';
import Button from 'components/shared/button/Button';
import styles from "./ConfirmDeleteModal.module.scss"

const ConfirmDeleteModal = ({ deleteLabel = "", handleDelete, ...props}) => {
    return (  
        <Modal 
            heading="Confirm Delete"
            {...props}
        >
            <p>Are you sure you want to delete {deleteLabel} ?</p>
            <div className={styles.buttonContainer}>
                <Button className={styles.cancelButton} onClick={() => props.setModalOpen(false)}>
                    Cancel
                </Button>
                <Button className={styles.deleteButton} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
        </Modal>
    );
}

// "This user is an approver for Krupali. Do you still want to deactivate ?"
 
export default ConfirmDeleteModal;