import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { ENTITY } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";

const initialState = {
    userDetails: [],
    totalUserDetails: 0,
    rejectedModalDetails: null,
    rejectedModalOpen: false,
    isFetching: false,
    allUserDetails: [],
    userDetailsPageName: ""
}

// UserDetails
export const getUserDetailsData = createAsyncThunk("userDetails/getUserDetailsData", async(params) => {
    // const res = await privateGet(ENTITY, { params })
    const res = await new Promise(resolve => {
        setTimeout(() => {
            resolve({
                data: {
                    data: Array.from({length: 14}).map((_, i) => ({
                        id: i + 1,
                        type: 1,
                        name: "Pranil Meherkar",
                        date: "25/03/2023",
                        postMonth: "07/2023",
                        accountNumber: "107101508543",
                        email: "pranil@gmail.com",
                        vendorName: "Nimap",
                        vendorCode: "BNKOTY908",
                        amount: 1000.00,
                        invoice: "IN948362GHL",
                        propCodeEntity: "Athreya Murti",
                    }))
                }
            })
        }, 1000)
    })
    return {
        data: res?.data?.data || [],
        totalUserDetails: res?.data?.count || 0
    }
})

export const getAllUserDetailsData = createAsyncThunk("userDetails/getAllUserDetailsData", async() => {
    const res = await privateGet(ENTITY)
    return {
        data: res?.data?.data || [],
    }
})

export const deleteUserDetails = createAsyncThunk("userDetails/deleteUserDetails", async(id, { dispatch }) => {
    await privateDelete(ENTITY, id)
    dispatch(getUserDetailsData())
    toast.success("UserDetails deleted successfully")
})

const userDetailsSlice = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setRejectedModalDetails: (state, action) => {
            state.rejectedModalDetails = action.payload
            state.rejectedModalOpen = !!action.payload
        },
        setRejectedModalOpen: (state, action) => {
            state.rejectedModalOpen = action.payload
        },
        setUserDetailsPageName: (state, action) => {
            state.userDetailsPageName = action.payload
        }
    },
    extraReducers: {
        [getUserDetailsData.pending]: (state) => {
            state.isFetching = true
        },
        [getUserDetailsData.fulfilled]: (state, action) => {
            state.userDetails = action.payload.data
            state.totalUserDetails = action.payload.totalUserDetails
            state.isFetching = false
        },
        [getUserDetailsData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllUserDetailsData.fulfilled]: (state, action) => {
            state.allUserDetails = action.payload.data
        },
    }
})

export default userDetailsSlice.reducer
export const { setIsFetching, setRejectedModalDetails, setRejectedModalOpen, setUserDetailsPageName } = userDetailsSlice.actions