import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TOTAL_AMOUNT } from "services/apiEndpoints";
import { privateGet } from "services/privateRequest";

const initialState = {
    cc: [],
    totalCc: 0,
    selectedCc: null,
    ccModalOpen: false,
    isFetching: false,
    allCc: [],
    totalAmount: 0,
    fetchingTotal: false,
}

// Cc

export const getAllCcData = createAsyncThunk("cc/getAllCcData", async({endpoint, params}) => {
    const res = await privateGet(endpoint, { params })
    return {
        data: res?.data?.data?.data || res?.data?.data || [],
        totalCc: res?.data?.count || 0,
        threeSum: [
            res?.data?.data?.sumOfApproved,
            res?.data?.data?.sumOfPending,
            res?.data?.data?.sumOfReject,
        ]
    }
})

export const getTotalAmount = createAsyncThunk("cc/getTotalAmount", async(params) => {
    const res = await privateGet(TOTAL_AMOUNT, { params })
    return {
        totalAmount: res?.data?.data?.[0]?.totalAmount
    }
})

const ccSlice = createSlice({
    name: "cc",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedCc: (state, action) => {
            state.selectedCc = action.payload
            state.ccModalOpen = !!action.payload
        },
        setCcModalOpen: (state, action) => {
            state.ccModalOpen = action.payload
        }
    },
    extraReducers: {
        [getAllCcData.pending]: (state) => {
            state.isFetching = true
            state.fetchingTotal = true
        },
        [getAllCcData.fulfilled]: (state, action) => {
            state.allCc = action.payload.data
            state.totalCc = action.payload.totalCc
            state.totalAmount = action.payload.threeSum
            state.isFetching = false
            state.fetchingTotal = false
        },
        [getAllCcData.rejected]: (state) => {
            state.isFetching = false
            state.fetchingTotal = false
        },
        [getTotalAmount.pending]: (state) => {
            state.fetchingTotal = true
        },
        [getTotalAmount.fulfilled]: (state, action) => {
            state.fetchingTotal = false
            state.totalAmount = action.payload.totalAmount
        },
        [getTotalAmount.rejected]: (state) => {
            state.fetchingTotal = false
        },
    }
})

export default ccSlice.reducer
export const { setIsFetching, setSelectedCc, setCcModalOpen } = ccSlice.actions