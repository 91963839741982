import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "components/shared/toast/toast";
import { ENTITY, USER_ACCOUNT } from "services/apiEndpoints";
import { privateDelete, privateGet } from "services/privateRequest";

const initialState = {
    userAccount: [],
    totalUserAccount: 0,
    selectedUserAccount: null,
    userAccountModalOpen: false,
    isFetching: false,
    allUserAccount: [],
}

// UserAccount
export const getUserAccountData = createAsyncThunk("userAccount/getUserAccountData", async(params) => {
    const res = await privateGet(USER_ACCOUNT, { params })
    // const res = {
    //     data: {
    //         data: await new Promise(resolve => {
    //             setTimeout(() => {
    //                 resolve(Array(12).fill(null).map((_, i) => ({
    //                     id: i + 1,
    //                     user: "Athreya Murthy",
    //                     userAccount: ["Siddhesh Mahale"]
    //                 })))
    //             }, 1000)
    //         }),
    //         count: 12
    //     }
    // }
    return {
        data: res?.data?.data || [],
        totalUserAccount: res?.data?.count || 0
    }
})

export const getAllUserAccountData = createAsyncThunk("userAccount/getAllUserAccountData", async() => {
    const res = await privateGet(ENTITY)
    return {
        data: res?.data?.data || [],
    }
})

export const deleteUserAccount = createAsyncThunk("userAccount/deleteUserAccount", async(id, { dispatch }) => {
    await privateDelete(ENTITY, id)
    dispatch(getUserAccountData())
    toast.success("User account deleted successfully")
})

const userAccountSlice = createSlice({
    name: "userAccount",
    initialState,
    reducers: {
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setSelectedUserAccount: (state, action) => {
            state.selectedUserAccount = action.payload
            state.userAccountModalOpen = !!action.payload
        },
        setUserAccountModalOpen: (state, action) => {
            state.userAccountModalOpen = action.payload
        }
    },
    extraReducers: {
        [getUserAccountData.pending]: (state) => {
            state.isFetching = true
        },
        [getUserAccountData.fulfilled]: (state, action) => {
            state.userAccount = action.payload.data
            state.totalUserAccount = action.payload.totalUserAccount
            state.isFetching = false
        },
        [getUserAccountData.rejected]: (state) => {
            state.isFetching = false
        },
        [getAllUserAccountData.fulfilled]: (state, action) => {
            state.allUserAccount = action.payload.data
        },
    }
})

export default userAccountSlice.reducer
export const { setIsFetching, setSelectedUserAccount, setUserAccountModalOpen } = userAccountSlice.actions